const downloadFile = (blob: Blob, filename: string) => {
	const url = window.URL.createObjectURL(blob)
	const anchorElement = document.createElement('a')
	anchorElement.href = url
	anchorElement.download = filename
	anchorElement.click()
	window.URL.revokeObjectURL(url)
}

export default downloadFile
