import { Portal } from '../../Portal'

export const ToetsmodelSideTextEN = () => {
	return (
		<g id="side-texts">
			<Portal
				trigger={
					<g id="assessmenttasks-bg">
						<rect x="120" y="80" fillOpacity={0} width="120" height="120" />
						<g id="assessmenttasks">
							<path
								d="M122.2,149.6l1.4-1.6l7.5,3.2l-1.2,1.4l-3.6-1.8c-0.7-0.3-1.6-0.8-2.3-1.1l0,0c0.5,0.7,1.1,1.4,1.5,2.1l2.3,3.3l-1.2,1.4
				L122.2,149.6z M125.2,153.2l2.4-2.8l1,0.9l-2.4,2.8L125.2,153.2z"
							/>
							<path
								d="M130.8,150.6l-0.3-1.3c0.7-0.1,1.1-0.4,1.5-0.7c0.3-0.4,0.4-0.7,0.1-0.9c-0.3-0.3-0.8,0.1-1.4,0.4
				c-0.7,0.4-1.6,0.7-2.3,0c-0.8-0.7-0.8-1.9,0.1-2.9c0.6-0.7,1.3-0.9,1.9-1l0.3,1.3c-0.5,0.1-0.9,0.3-1.2,0.6
				c-0.3,0.3-0.3,0.6-0.1,0.8c0.3,0.3,0.8-0.1,1.3-0.4c0.7-0.4,1.6-0.7,2.4,0c0.8,0.7,0.9,1.9-0.2,3.1
				C132.4,150,131.5,150.4,130.8,150.6z"
							/>
							<path
								d="M134.3,146.7l-0.3-1.3c0.7-0.1,1.2-0.3,1.5-0.7c0.4-0.4,0.4-0.7,0.1-0.9c-0.3-0.3-0.8,0-1.4,0.3
				c-0.7,0.3-1.6,0.6-2.3-0.1c-0.8-0.8-0.7-1.9,0.2-2.9c0.6-0.7,1.3-0.9,2-1l0.2,1.3c-0.5,0.1-0.9,0.3-1.2,0.6
				c-0.3,0.3-0.3,0.6-0.1,0.8c0.3,0.3,0.8,0,1.3-0.3c0.7-0.4,1.6-0.7,2.4,0.1c0.8,0.7,0.8,1.9-0.3,3.1
				C135.9,146.1,135,146.6,134.3,146.7z"
							/>
							<path
								d="M136.4,141.1c-1.4-1.3-1.2-3.2-0.2-4.2c1.2-1.2,2.6-0.9,3.8,0.2c0.2,0.2,0.4,0.5,0.5,0.6l-2.5,2.5c0.7,0.5,1.4,0.4,2-0.2
				c0.3-0.3,0.5-0.7,0.6-1.2l1.2,0.3c-0.1,0.7-0.5,1.5-1,2C139.5,142.4,137.7,142.5,136.4,141.1z M138.6,138c-0.5-0.5-1-0.6-1.5-0.1
				c-0.4,0.4-0.5,1,0,1.6L138.6,138z"
							/>
							<path
								d="M142.2,138.5l-0.2-1.3c0.7-0.1,1.2-0.3,1.5-0.6c0.4-0.4,0.4-0.7,0.2-0.9c-0.3-0.3-0.8,0-1.4,0.2
				c-0.7,0.3-1.7,0.5-2.3-0.2c-0.8-0.8-0.6-2,0.3-2.9c0.7-0.6,1.4-0.8,2-0.9l0.2,1.3c-0.5,0.1-1,0.2-1.2,0.5
				c-0.3,0.3-0.4,0.6-0.2,0.8c0.3,0.3,0.8,0,1.4-0.3c0.7-0.3,1.6-0.6,2.4,0.2c0.7,0.8,0.7,2-0.4,3.1
				C143.8,138.1,142.9,138.5,142.2,138.5z"
							/>
							<path
								d="M146,134.9l-0.2-1.3c0.7-0.1,1.2-0.2,1.5-0.6c0.4-0.4,0.4-0.7,0.2-0.9c-0.3-0.3-0.8,0-1.4,0.2c-0.7,0.3-1.7,0.5-2.3-0.2
				c-0.7-0.8-0.6-2,0.4-2.9c0.7-0.6,1.4-0.8,2-0.9l0.1,1.3c-0.5,0.1-1,0.2-1.3,0.5c-0.3,0.3-0.4,0.6-0.2,0.8c0.3,0.3,0.8,0,1.4-0.2
				c0.7-0.3,1.6-0.6,2.4,0.2c0.7,0.8,0.7,2-0.5,3C147.6,134.5,146.7,134.9,146,134.9z"
							/>
							<path
								d="M146.8,127l1.1-0.9l0.6,0.5l0,0c0.1-0.7,0.2-1.3,0.8-1.8c0.6-0.5,1.2-0.6,1.9-0.3c0-0.7,0.2-1.5,0.8-2
				c1-0.8,2-0.5,2.9,0.6l2.4,2.8l-1.3,1.1l-2.2-2.6c-0.5-0.6-0.9-0.7-1.3-0.4c-0.2,0.2-0.4,0.6-0.4,1.1l2.6,3l-1.3,1.1l-2.2-2.6
				c-0.6-0.6-0.9-0.7-1.3-0.4c-0.2,0.2-0.4,0.6-0.4,1.1l2.6,3l-1.3,1.1L146.8,127z"
							/>
							<path
								d="M156.3,122.9c-1.2-1.5-0.8-3.3,0.3-4.2c1.3-1,2.7-0.6,3.7,0.7c0.2,0.2,0.4,0.5,0.4,0.7l-2.8,2.2c0.7,0.6,1.4,0.5,2,0
				c0.4-0.3,0.6-0.6,0.8-1.1l1.1,0.5c-0.2,0.7-0.7,1.4-1.2,1.8C159.2,124.5,157.5,124.4,156.3,122.9z M158.9,120.1
				c-0.4-0.5-0.9-0.7-1.5-0.3c-0.4,0.3-0.6,0.9-0.2,1.6L158.9,120.1z"
							/>
							<path
								d="M159.7,116.5l1.1-0.9l0.5,0.5l0,0c0.1-0.7,0.3-1.4,1-1.8c1-0.8,2-0.4,2.9,0.8l2.2,2.9l-1.4,1l-2.1-2.8
				c-0.5-0.7-0.8-0.8-1.3-0.5c-0.4,0.3-0.4,0.6-0.5,1.1l2.4,3.2l-1.4,1L159.7,116.5z"
							/>
							<path
								d="M167.6,115.4l-1.4-1.9l-0.7,0.5l-0.8-1.1l0.7-0.6l-0.7-1.4l1.2-0.8l0.9,1.3l1.2-0.8l0.8,1.1l-1.2,0.8l1.4,1.9
				c0.4,0.5,0.8,0.6,1.2,0.3c0.2-0.1,0.3-0.3,0.4-0.4l1,0.9c-0.2,0.3-0.5,0.6-0.9,0.9C169.4,116.9,168.4,116.5,167.6,115.4z"
							/>
							<path
								d="M172.5,110.5c-1.2-1.7-1.6-3.5-1.7-5.4l1.2-0.2c0.1,1.8,0.8,3.4,1.7,4.8c0.9,1.4,2.2,2.7,3.8,3.5l-0.7,1
				C175.1,113.4,173.7,112.3,172.5,110.5z"
							/>
							<path
								d="M176.7,109.2l-1.3-2l-0.7,0.4l-0.7-1.1l0.7-0.5l-0.7-1.4l1.2-0.8l0.8,1.3l1.2-0.8l0.7,1.2l-1.2,0.8l1.3,2
				c0.4,0.6,0.8,0.6,1.1,0.4c0.2-0.1,0.3-0.3,0.4-0.4l0.9,0.9c-0.2,0.3-0.5,0.6-1,0.9C178.4,110.8,177.4,110.3,176.7,109.2z"
							/>
							<path
								d="M180.2,107.6c-0.6-1.1-0.1-2.2,1.7-3.6c-0.3-0.4-0.7-0.6-1.2-0.3c-0.4,0.3-0.7,0.7-1.1,1.3l-1.1-0.7c0.4-0.8,1-1.5,1.8-2
				c1.3-0.8,2.5-0.5,3.4,1.1l1.7,2.9l-1.2,0.7l-0.4-0.5l0,0c-0.2,0.6-0.5,1.2-1.1,1.5C181.7,108.8,180.7,108.4,180.2,107.6z
				 M183,105.8l-0.5-0.9c-1,0.8-1.1,1.3-0.9,1.7c0.2,0.3,0.5,0.4,0.9,0.1C182.8,106.5,182.9,106.2,183,105.8z"
							/>
							<path
								d="M185.8,105.3l0.1-1.3c0.7,0.1,1.2,0,1.6-0.2c0.5-0.3,0.6-0.5,0.4-0.8c-0.2-0.3-0.8-0.2-1.4-0.1c-0.8,0.1-1.7,0.1-2.2-0.7
				c-0.5-1-0.1-2,1-2.7c0.8-0.4,1.6-0.5,2.2-0.4l-0.2,1.3c-0.5-0.1-1,0-1.3,0.2c-0.4,0.2-0.5,0.5-0.4,0.7c0.2,0.3,0.7,0.2,1.4,0.1
				c0.8-0.1,1.7-0.2,2.3,0.8c0.5,0.9,0.2,2.1-1.2,2.8C187.5,105.3,186.6,105.5,185.8,105.3z"
							/>
							<path
								d="M187.2,95.7l1.5-0.8l2.2,4.2l0,0l0.6-2.9l1.7-0.9l-0.7,3.1l3.6,2l-1.7,0.9l-2.3-1.4L192,101l0.7,1.3l-1.5,0.8L187.2,95.7
				z"
							/>
							<path
								d="M196.1,99.7l0.2-1.3c0.7,0.1,1.2,0.1,1.6-0.1c0.5-0.2,0.6-0.5,0.4-0.8c-0.2-0.3-0.8-0.2-1.4-0.2c-0.8,0.1-1.7,0-2.2-0.9
				c-0.5-1,0-2.1,1.2-2.7c0.8-0.4,1.6-0.4,2.2-0.3l-0.2,1.3c-0.5-0.1-1-0.1-1.3,0.1c-0.4,0.2-0.5,0.5-0.4,0.7
				c0.2,0.3,0.7,0.2,1.4,0.1c0.8-0.1,1.7-0.1,2.2,0.9c0.5,1,0.1,2.1-1.3,2.8C197.8,99.8,196.8,99.9,196.1,99.7z"
							/>
							<path d="M202.1,99.4c0.1-1.8-0.3-3.5-1-5s-1.8-2.9-3.3-4l0.8-0.9c1.6,1,2.8,2.3,3.7,4.2s1.1,3.7,0.9,5.6L202.1,99.4z" />
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Assessment (tasks)</h4>
					<p>
						Assessments are (learning) activities/measurement instruments used
						to establish whether the intended learning outcomes have been
						achieved (Joosten-ten Brinke & Draaier, 2015). The quality of the
						assessment as a whole also includes the design of the assessment
						models and instructions for students and assessors. The assessment
						tasks are the items, questions or assignments within an assessment
						whereby students are challenged to demonstrate their knowledge and
						skills (Draaier & Joosten-ten Brinke, 2015).
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentprogram-bg">
						<rect x="380" y="80" fillOpacity={0} width="120" height="120" />
						<g id="assessmentprogram">
							<path
								d="M392.9,88.8l1.9,0.9l-1,8.1L392,97l0.7-4c0.1-0.8,0.3-1.7,0.5-2.5l0,0c-0.5,0.7-1.1,1.4-1.6,2l-2.6,3.1l-1.6-0.8
				L392.9,88.8z M390.2,92.6l3.4,1.6l-0.6,1.2l-3.4-1.6L390.2,92.6z"
							/>
							<path
								d="M394.3,97.3l1.2-0.6c0.3,0.6,0.6,1,1.1,1.2c0.5,0.2,0.8,0.2,0.9-0.1c0.2-0.3-0.3-0.8-0.7-1.2c-0.5-0.6-1.1-1.4-0.6-2.2
				c0.5-1,1.6-1.3,2.8-0.7c0.8,0.4,1.3,1,1.5,1.6l-1.2,0.6c-0.2-0.5-0.5-0.8-0.9-1c-0.4-0.2-0.7-0.1-0.8,0.1
				c-0.2,0.3,0.3,0.7,0.7,1.2c0.5,0.6,1.1,1.3,0.6,2.3c-0.5,1-1.6,1.3-3,0.6C395.2,98.7,394.6,98,394.3,97.3z"
							/>
							<path
								d="M398.9,99.6l1.2-0.6c0.3,0.6,0.6,1,1.1,1.3c0.5,0.2,0.8,0.2,0.9-0.1c0.2-0.3-0.3-0.8-0.7-1.3c-0.5-0.6-1-1.4-0.6-2.3
				c0.5-1,1.7-1.2,2.9-0.6c0.8,0.4,1.2,1.1,1.5,1.6l-1.2,0.6c-0.2-0.5-0.5-0.8-0.9-1c-0.4-0.2-0.7-0.2-0.8,0.1
				c-0.2,0.3,0.2,0.7,0.7,1.2c0.5,0.6,1.1,1.4,0.6,2.3c-0.5,0.9-1.6,1.3-3,0.6C399.9,101,399.2,100.3,398.9,99.6z"
							/>
							<path
								d="M404.8,100.2c0.9-1.7,2.7-2,3.9-1.3c1.5,0.8,1.6,2.3,0.8,3.7c-0.2,0.3-0.3,0.5-0.4,0.6l-3.1-1.7c-0.3,0.8,0,1.5,0.7,1.8
				c0.4,0.2,0.8,0.3,1.3,0.3l0,1.2c-0.7,0.1-1.5-0.1-2.2-0.4C404.5,103.5,403.9,101.9,404.8,100.2z M408.5,101.5
				c0.3-0.6,0.3-1.1-0.3-1.5c-0.5-0.3-1.1-0.2-1.5,0.4L408.5,101.5z"
							/>
							<path
								d="M408.9,105.1l1.2-0.5c0.3,0.6,0.6,1.1,1,1.3c0.4,0.3,0.7,0.2,0.9-0.1c0.2-0.3-0.2-0.8-0.6-1.3c-0.5-0.6-1-1.4-0.5-2.3
				c0.6-0.9,1.7-1.1,2.9-0.5c0.8,0.5,1.2,1.1,1.4,1.7l-1.2,0.5c-0.2-0.5-0.5-0.9-0.8-1.1c-0.4-0.2-0.7-0.2-0.8,0.1
				c-0.2,0.3,0.2,0.7,0.6,1.2c0.5,0.6,1,1.4,0.5,2.3c-0.5,0.9-1.7,1.2-3.1,0.4C409.8,106.5,409.2,105.8,408.9,105.1z"
							/>
							<path
								d="M413.4,107.7l1.2-0.5c0.2,0.6,0.5,1.1,1,1.3c0.4,0.3,0.7,0.2,0.9,0c0.2-0.3-0.2-0.8-0.6-1.3c-0.5-0.6-0.9-1.5-0.4-2.3
				c0.6-0.9,1.7-1.1,2.9-0.4c0.8,0.5,1.1,1.1,1.4,1.7l-1.2,0.5c-0.2-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-0.7-0.2-0.8,0
				c-0.2,0.3,0.2,0.7,0.6,1.2c0.5,0.6,1,1.4,0.4,2.3c-0.6,0.9-1.7,1.2-3.1,0.3C414.2,109.2,413.7,108.4,413.4,107.7z"
							/>
							<path
								d="M421.2,106.4l1.2,0.8l-0.3,0.7l0,0c0.7-0.1,1.3-0.2,2,0.3c0.7,0.5,0.9,1,0.8,1.7c0.7-0.2,1.4-0.2,2.1,0.2
				c1.1,0.7,1,1.8,0.2,3l-2,3l-1.5-1l1.9-2.9c0.5-0.7,0.4-1.1,0-1.3c-0.2-0.2-0.6-0.2-1.2-0.1l-2.2,3.3l-1.5-1l1.9-2.9
				c0.5-0.7,0.4-1.1,0-1.3c-0.2-0.2-0.6-0.2-1.2-0.1l-2.2,3.3l-1.5-1L421.2,106.4z"
							/>
							<path
								d="M427.8,114.4c1.1-1.5,2.9-1.7,4.1-0.9c1.4,1,1.3,2.5,0.4,3.8c-0.2,0.3-0.4,0.5-0.5,0.6l-2.9-2.1
				c-0.4,0.8-0.1,1.4,0.5,1.9c0.4,0.3,0.8,0.4,1.3,0.4l-0.2,1.2c-0.7,0-1.5-0.3-2.1-0.7C427,117.7,426.6,116,427.8,114.4z
				 M431.2,116.1c0.4-0.5,0.4-1.1-0.1-1.5c-0.4-0.3-1-0.3-1.6,0.2L431.2,116.1z"
							/>
							<path
								d="M434.8,115.9l1.1,0.9l-0.4,0.7l0,0c0.7-0.1,1.4,0,2,0.5c1,0.8,0.9,1.8,0,3l-2.2,2.9l-1.4-1.1l2.1-2.7
				c0.5-0.7,0.5-1,0.1-1.3c-0.3-0.3-0.7-0.3-1.2-0.2l-2.4,3.2l-1.4-1.1L434.8,115.9z"
							/>
							<path
								d="M438.1,123.3l1.5-1.8l-0.6-0.5l0.8-1l0.7,0.5l1.1-1.1l1.1,0.9l-1,1.2l1.1,0.9l-0.9,1.1l-1.1-0.9l-1.5,1.8
				c-0.4,0.5-0.4,0.9,0,1.2c0.2,0.1,0.4,0.2,0.5,0.3l-0.6,1.2c-0.3-0.1-0.7-0.3-1.2-0.7C437.1,125.4,437.2,124.3,438.1,123.3z"
							/>
							<path
								d="M445.7,124.7l1.1,0.9l-0.3,0.5l0,0c0.6,0,1.3,0.2,1.7,0.6c1.1,0.9,1,2.4-0.2,3.8c-1.3,1.5-3,1.6-4,0.8
				c-0.4-0.3-0.6-0.8-0.7-1.4l-0.6,0.7l-1.1,1.3l-1.3-1.2L445.7,124.7z M446.7,129.3c0.7-0.8,0.8-1.4,0.3-1.9
				c-0.3-0.2-0.6-0.3-1.1-0.3l-1.6,1.8c0.1,0.4,0.2,0.7,0.5,0.9C445.2,130.3,445.9,130.3,446.7,129.3z"
							/>
							<path
								d="M450.9,129.3l1,1l-0.6,0.8l0,0c0.9-0.3,1.6-0.1,2,0.3c0.2,0.2,0.4,0.4,0.4,0.6l-1.2,0.9c-0.1-0.2-0.2-0.3-0.4-0.5
				c-0.3-0.3-0.9-0.5-1.7-0.1l-2.3,2.5l-1.3-1.2L450.9,129.3z"
							/>
							<path
								d="M452.1,134.3c1.4-1.4,3.1-1.3,4.2-0.2c1.1,1,1.3,2.8-0.1,4.2c-1.4,1.4-3.1,1.3-4.2,0.2
				C450.9,137.5,450.7,135.7,452.1,134.3z M454.9,137.1c0.7-0.7,0.9-1.5,0.4-2c-0.5-0.5-1.2-0.3-1.9,0.5c-0.7,0.7-0.9,1.5-0.4,2
				C453.5,138.1,454.2,137.8,454.9,137.1z"
							/>
							<path
								d="M454,141.7c0.4-0.3,0.8-0.4,1.4-0.2l0,0c-0.1-0.4,0-0.7,0.3-1.1c0.3-0.3,0.8-0.4,1.3-0.3l0,0c-0.1-0.5,0-1.2,0.5-1.6
				c1-1,2.3-0.7,3.2,0.3c0.2,0.2,0.4,0.5,0.5,0.8l1.5,1.5l-0.9,0.9l-0.7-0.7c0,0.2-0.2,0.5-0.4,0.7c-1,0.9-2.1,0.6-3-0.3
				c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2,0-0.3,0-0.5,0.2c-0.2,0.2-0.1,0.5,0.3,0.9l0.7,0.7c1,1,1.3,1.9,0.5,2.7c-0.9,0.9-2.4,0.6-3.8-0.9
				C453.7,143.5,453.2,142.4,454,141.7z M457.1,144.4c0.3-0.3,0.1-0.5-0.3-1l-0.4-0.4c-0.3-0.3-0.4-0.5-0.5-0.6
				c-0.3,0-0.5,0-0.6,0.2c-0.3,0.3-0.1,0.8,0.4,1.4C456.1,144.5,456.8,144.7,457.1,144.4z M459.9,140.7c0.4-0.4,0.4-0.9,0.1-1.2
				c-0.3-0.3-0.8-0.3-1.2,0.1c-0.5,0.5-0.5,0.9-0.1,1.3S459.4,141.2,459.9,140.7z"
							/>
							<path
								d="M463.7,141.8l1,1l-0.7,0.8l0,0c0.9-0.2,1.6,0,2,0.4c0.2,0.2,0.3,0.4,0.4,0.6l-1.3,0.8c-0.1-0.2-0.2-0.3-0.4-0.5
				c-0.3-0.3-0.9-0.5-1.7-0.2l-2.5,2.3l-1.2-1.3L463.7,141.8z"
							/>
							<path
								d="M463.3,147.7c0.9-0.8,2.1-0.5,3.8,1.1c0.4-0.4,0.4-0.8,0-1.3c-0.3-0.4-0.8-0.6-1.4-0.8l0.4-1.2c0.8,0.3,1.6,0.7,2.2,1.4
				c1,1.1,0.9,2.3-0.4,3.5l-2.5,2.2l-0.9-1.1l0.4-0.5l0,0c-0.7-0.1-1.3-0.3-1.7-0.8C462.4,149.4,462.6,148.4,463.3,147.7z
				 M465.6,150.2l0.8-0.7c-0.9-0.8-1.5-0.9-1.8-0.6c-0.3,0.3-0.3,0.6,0,0.9C464.8,150.1,465.1,150.1,465.6,150.2z"
							/>
							<path
								d="M471,149.9l0.9,1.1l-0.5,0.6l0,0c0.7,0.1,1.3,0.2,1.8,0.8c0.5,0.6,0.6,1.3,0.3,1.9c0.7,0.1,1.4,0.2,1.9,0.8
				c0.8,1,0.5,2-0.7,2.9l-2.8,2.3l-1.1-1.4l2.7-2.2c0.7-0.5,0.7-0.9,0.4-1.3c-0.2-0.2-0.5-0.4-1.1-0.4l-3.1,2.5l-1.1-1.4l2.7-2.2
				c0.7-0.5,0.7-0.9,0.4-1.3c-0.2-0.2-0.5-0.4-1.1-0.4l-3.1,2.5l-1.1-1.4L471,149.9z"
							/>
							<path
								d="M477.5,157.9l0.9,1.1l-0.5,0.6l0,0c0.7,0.1,1.3,0.3,1.8,0.9c0.5,0.7,0.5,1.3,0.2,1.9c0.7,0.1,1.4,0.3,1.9,0.9
				c0.8,1,0.4,2-0.8,2.9l-2.9,2.2l-1-1.4l2.8-2.1c0.7-0.5,0.8-0.9,0.5-1.3c-0.2-0.2-0.5-0.4-1.1-0.5l-3.2,2.4l-1-1.4l2.8-2.1
				c0.7-0.5,0.8-0.9,0.5-1.3c-0.2-0.2-0.5-0.4-1.1-0.5l-3.2,2.4l-1-1.4L477.5,157.9z"
							/>
							<path
								d="M481,167.6c1.6-1.1,3.3-0.6,4.1,0.5c1,1.4,0.4,2.8-0.9,3.7c-0.3,0.2-0.5,0.3-0.7,0.4l-2-2.9c-0.6,0.6-0.6,1.3-0.2,2
				c0.3,0.4,0.6,0.6,1,0.8l-0.5,1.1c-0.7-0.3-1.3-0.8-1.7-1.3C479.2,170.5,479.4,168.8,481,167.6z M483.7,170.4
				c0.5-0.4,0.8-0.9,0.4-1.4c-0.3-0.5-0.9-0.6-1.6-0.3L483.7,170.4z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Assessment programme</h4>
					<p>
						The assessment programme is an intentional and substantiated
						combination of assessment types that match the objectives and
						structure of a degree course (study programme) (Baartman & Van der
						Vleuten, 2015). It is about the apparent relationship between
						assessment and the assurance of the different functions of
						assessment.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentpolicy-bg">
						<rect x="450" y="300" width="120" height="120" fillOpacity={0} />
						<g id="assessmentpolicy">
							<path
								d="M526.3,318.1l-0.2,2.1l-7.9,1.7l0.2-1.8l4-0.6c0.8-0.1,1.7-0.3,2.5-0.4l0,0c-0.8-0.3-1.7-0.6-2.4-0.8l-3.8-1.4l0.2-1.8
				L526.3,318.1z M521.8,316.8l-0.3,3.7l-1.4-0.1l0.3-3.7L521.8,316.8z"
							/>
							<path
								d="M518.8,322.2l1,0.9c-0.5,0.5-0.7,0.9-0.8,1.4c-0.1,0.5,0.1,0.8,0.4,0.8c0.4,0,0.6-0.5,0.9-1.1c0.4-0.7,0.9-1.5,1.9-1.3
				c1.1,0.1,1.7,1.1,1.6,2.5c-0.1,0.9-0.6,1.5-1,2l-0.9-0.9c0.4-0.4,0.6-0.8,0.7-1.2c0.1-0.4-0.1-0.7-0.4-0.7
				c-0.4,0-0.6,0.5-0.9,1.1c-0.4,0.7-0.9,1.5-2,1.4c-1.1-0.1-1.8-1.1-1.6-2.6C517.8,323.6,518.2,322.7,518.8,322.2z"
							/>
							<path
								d="M518.1,327.4l1,0.9c-0.5,0.5-0.8,0.9-0.8,1.4c-0.1,0.5,0.1,0.8,0.4,0.8c0.4,0.1,0.6-0.5,1-1.1c0.4-0.7,1-1.4,1.9-1.3
				c1.1,0.2,1.7,1.1,1.5,2.5c-0.1,0.9-0.6,1.5-1,2l-0.9-0.9c0.4-0.4,0.6-0.8,0.7-1.2c0.1-0.4-0.1-0.7-0.4-0.7
				c-0.4-0.1-0.6,0.5-0.9,1c-0.4,0.7-0.9,1.5-2,1.3c-1.1-0.2-1.8-1.1-1.5-2.7C517.1,328.8,517.6,327.9,518.1,327.4z"
							/>
							<path
								d="M519.6,333.2c1.9,0.3,2.8,1.9,2.6,3.3c-0.3,1.7-1.6,2.3-3.2,2c-0.3-0.1-0.6-0.1-0.7-0.2l0.6-3.5c-0.9,0-1.4,0.5-1.5,1.3
				c-0.1,0.4,0,0.9,0.2,1.3l-1.1,0.4c-0.3-0.7-0.4-1.5-0.3-2.2C516.3,333.9,517.7,332.8,519.6,333.2z M519.6,337
				c0.6,0.1,1.1-0.1,1.3-0.8c0.1-0.5-0.2-1.1-0.9-1.3L519.6,337z"
							/>
							<path
								d="M516.3,338.6l0.9,1c-0.5,0.4-0.8,0.9-0.9,1.4c-0.1,0.5,0.1,0.8,0.4,0.8c0.4,0.1,0.7-0.5,1-1c0.4-0.7,1-1.4,2-1.2
				c1.1,0.2,1.6,1.2,1.4,2.6c-0.2,0.9-0.7,1.5-1.1,1.9l-0.9-1c0.4-0.4,0.7-0.7,0.7-1.1c0.1-0.4,0-0.7-0.3-0.8c-0.4-0.1-0.6,0.4-1,1
				c-0.4,0.7-1,1.4-2.1,1.2c-1-0.2-1.7-1.2-1.4-2.7C515.2,339.9,515.7,339.1,516.3,338.6z"
							/>
							<path
								d="M515.3,343.8l0.9,1c-0.5,0.4-0.8,0.9-0.9,1.3c-0.1,0.5,0,0.8,0.3,0.8c0.4,0.1,0.7-0.4,1-1c0.4-0.6,1.1-1.4,2-1.1
				c1.1,0.2,1.6,1.3,1.3,2.6c-0.2,0.9-0.7,1.5-1.2,1.9l-0.9-1c0.4-0.3,0.7-0.7,0.8-1.1c0.1-0.4,0-0.7-0.3-0.8c-0.4-0.1-0.6,0.4-1,1
				c-0.4,0.7-1,1.4-2.1,1.2c-1-0.2-1.7-1.2-1.3-2.8C514.2,345,514.7,344.2,515.3,343.8z"
							/>
							<path
								d="M519.1,350.7l-0.4,1.4L518,352l0,0c0.3,0.6,0.6,1.2,0.4,2c-0.2,0.8-0.7,1.2-1.4,1.3c0.4,0.6,0.7,1.3,0.5,2
				c-0.3,1.2-1.3,1.6-2.8,1.2l-3.5-0.9l0.4-1.7l3.3,0.9c0.8,0.2,1.1,0.1,1.3-0.4c0.1-0.3,0-0.7-0.3-1.1l-3.9-1l0.4-1.7l3.3,0.9
				c0.8,0.2,1.1,0.1,1.3-0.4c0.1-0.3,0-0.7-0.3-1.1l-3.8-1l0.4-1.7L519.1,350.7z"
							/>
							<path
								d="M513.7,359.5c1.8,0.5,2.6,2.2,2.2,3.6c-0.5,1.6-1.9,2.1-3.4,1.6c-0.3-0.1-0.6-0.2-0.7-0.3l1-3.4
				c-0.9-0.1-1.4,0.4-1.6,1.1c-0.1,0.4-0.1,0.9,0,1.3l-1.2,0.2c-0.2-0.7-0.2-1.5,0-2.2C510.4,359.9,511.9,358.9,513.7,359.5z
				 M513.2,363.3c0.6,0.2,1.2,0.1,1.3-0.6c0.2-0.5-0.1-1.1-0.7-1.4L513.2,363.3z"
							/>
							<path
								d="M514.6,366.7l-0.5,1.4l-0.7-0.1l0,0c0.3,0.6,0.5,1.3,0.2,2.1c-0.4,1.2-1.4,1.5-2.8,1l-3.5-1.2l0.6-1.7l3.3,1.1
				c0.8,0.3,1.1,0.1,1.3-0.3c0.1-0.4,0-0.7-0.2-1.2l-3.8-1.3l0.6-1.7L514.6,366.7z"
							/>
							<path
								d="M508.8,372.2l2.2,0.8l0.3-0.8l1.2,0.4l-0.2,0.9l1.4,0.7l-0.5,1.4l-1.5-0.5l-0.5,1.3L510,376l0.5-1.3l-2.2-0.8
				c-0.6-0.2-1,0-1.1,0.4c-0.1,0.2-0.1,0.4-0.1,0.6l-1.3-0.2c0-0.3,0-0.8,0.2-1.3C506.5,371.9,507.5,371.7,508.8,372.2z"
							/>
							<path
								d="M510,379.9l-0.5,1.3l-0.6-0.1l0,0c0.2,0.6,0.3,1.3,0,1.8c-0.5,1.3-2,1.7-3.6,1c-1.9-0.8-2.5-2.3-2-3.5
				c0.2-0.5,0.6-0.8,1.1-1.1l-0.9-0.3l-1.6-0.6l0.7-1.6L510,379.9z M505.9,382.3c1,0.4,1.6,0.3,1.9-0.4c0.1-0.3,0.1-0.7-0.1-1.1
				l-2.2-0.9c-0.4,0.2-0.6,0.5-0.7,0.7C504.5,381.2,504.8,381.8,505.9,382.3z"
							/>
							<path
								d="M504.8,384.8c1.8,0.8,2.3,2.5,1.7,3.9c-0.6,1.4-2.2,2.2-4,1.4c-1.8-0.8-2.3-2.5-1.7-3.9S503,384,504.8,384.8z
				 M503.2,388.4c0.9,0.4,1.7,0.3,2-0.3c0.3-0.7-0.2-1.3-1.1-1.7c-0.9-0.4-1.7-0.3-2,0.3C501.8,387.4,502.3,388,503.2,388.4z"
							/>
							<path
								d="M501,390.6l5.9,2.7l-0.7,1.6l-6-2.8c-0.3-0.2-0.5,0-0.6,0.1c0,0.1,0,0.1-0.1,0.2l-1.3-0.4c0-0.2,0.1-0.5,0.2-0.9
				C499,390.1,499.9,390.1,501,390.6z"
							/>
							<path
								d="M503.2,395.4l-0.8,1.6l-5.3-2.6l0.8-1.6L503.2,395.4z M504.9,396.1c0.5,0.2,0.6,0.8,0.4,1.3c-0.3,0.5-0.8,0.7-1.3,0.5
				c-0.5-0.2-0.6-0.8-0.4-1.3C503.9,396.1,504.4,395.9,504.9,396.1z"
							/>
							<path
								d="M499.3,396.8c1.8,0.9,2.1,2.7,1.4,4.1c-0.3,0.6-0.8,1-1.3,1.2l-0.6-1.2c0.3-0.1,0.5-0.3,0.7-0.6c0.4-0.8,0-1.5-0.9-2
				c-0.9-0.5-1.8-0.3-2.1,0.4c-0.2,0.3-0.2,0.8-0.1,1.1l-1.3,0.1c-0.2-0.7,0-1.5,0.3-2.1C496,396.6,497.5,395.9,499.3,396.8z"
							/>
							<path
								d="M492.2,399.5l1,0.9c-0.1,0.1-0.2,0.2-0.3,0.3c-0.3,0.5-0.1,0.9,0.2,1.3l0.2,0.2l6.1,0.7l-0.8,1.5L496,404
				c-0.6-0.1-1.1-0.2-1.7-0.4l0,0c0.4,0.4,0.9,0.8,1.3,1.2l1.9,1.8l-0.8,1.5l-4.3-4.6c-1-1.2-1.4-2.1-0.8-3.3
				C491.8,399.9,492,399.7,492.2,399.5z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Assessment policy</h4>
					<p>
						Assessment policy refers to the entire range of substantive and
						procedural agreements concerning assessment (Bruijns & Kok, 2015).
						Several levels can be distinguished within the integral assessment
						policy, including the policy at the institutional level and the
						degree course level.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="toetsorganisatie-bg">
						<rect x="225" y="500" width="150" height="50" fillOpacity={0} />
						<g id="toetsorganisatie">
							<path
								d="M237.7,509.4l2,0.7l-0.4,8.1l-1.7-0.6l0.4-4c0.1-0.8,0.2-1.7,0.3-2.6l0,0c-0.5,0.7-1,1.5-1.4,2.1l-2.3,3.3l-1.7-0.6
					L237.7,509.4z M235.4,513.4l3.5,1.3l-0.5,1.3l-3.5-1.3L235.4,513.4z"
							/>
							<path
								d="M240.1,517.7l1.1-0.8c0.4,0.5,0.8,0.9,1.3,1.1c0.5,0.2,0.8,0,0.9-0.3c0.1-0.4-0.4-0.7-0.9-1.1c-0.6-0.5-1.3-1.2-1-2.1
					c0.3-1,1.4-1.5,2.7-1.1c0.9,0.3,1.4,0.8,1.7,1.3l-1.1,0.8c-0.3-0.4-0.6-0.7-1-0.9c-0.4-0.1-0.7,0-0.8,0.2
					c-0.1,0.4,0.4,0.7,0.9,1.1c0.6,0.5,1.3,1.2,1,2.2c-0.3,1-1.4,1.6-2.9,1.1C241.2,519,240.5,518.4,240.1,517.7z"
							/>
							<path
								d="M245.2,519.4l1.1-0.8c0.4,0.5,0.8,0.9,1.3,1c0.5,0.1,0.8,0,0.9-0.3c0.1-0.4-0.4-0.7-0.9-1.1c-0.6-0.5-1.3-1.2-1-2.1
					c0.3-1.1,1.4-1.5,2.7-1.1c0.9,0.2,1.4,0.8,1.8,1.3l-1,0.8c-0.3-0.4-0.7-0.7-1.1-0.8c-0.4-0.1-0.7,0-0.8,0.3
					c-0.1,0.4,0.4,0.7,0.9,1c0.6,0.5,1.3,1.1,1,2.1c-0.3,1-1.3,1.6-2.9,1.2C246.4,520.6,245.6,520,245.2,519.4z"
							/>
							<path
								d="M251.1,518.7c0.5-1.9,2.1-2.7,3.5-2.3c1.6,0.4,2.1,1.8,1.7,3.4c-0.1,0.3-0.2,0.6-0.3,0.7l-3.4-0.9
					c-0.1,0.9,0.4,1.4,1.2,1.6c0.4,0.1,0.9,0.1,1.3-0.1l0.3,1.2c-0.7,0.3-1.5,0.3-2.2,0.1C251.6,522,250.7,520.6,251.1,518.7z
					 M255,519c0.2-0.6,0-1.1-0.7-1.3c-0.5-0.1-1.1,0.1-1.4,0.8L255,519z"
							/>
							<path
								d="M256.5,522.4l1-0.9c0.4,0.5,0.9,0.8,1.4,0.9c0.5,0.1,0.8,0,0.8-0.3c0.1-0.4-0.5-0.7-1-1c-0.6-0.4-1.4-1.1-1.2-2
					c0.2-1.1,1.3-1.6,2.6-1.3c0.9,0.2,1.5,0.7,1.9,1.2l-1,0.9c-0.3-0.4-0.7-0.7-1.1-0.8c-0.4-0.1-0.7,0-0.8,0.3
					c-0.1,0.4,0.4,0.6,1,1c0.7,0.4,1.4,1,1.2,2.1c-0.2,1-1.2,1.7-2.8,1.3C257.8,523.5,257,523,256.5,522.4z"
							/>
							<path
								d="M261.8,523.5l1-0.9c0.5,0.5,0.9,0.8,1.4,0.9c0.5,0.1,0.8-0.1,0.8-0.4c0.1-0.4-0.5-0.7-1-1c-0.7-0.4-1.4-1-1.2-2
					c0.2-1.1,1.2-1.6,2.6-1.4c0.9,0.2,1.5,0.7,1.9,1.1l-1,0.9c-0.4-0.4-0.7-0.6-1.1-0.7c-0.4-0.1-0.7,0-0.8,0.3
					c-0.1,0.4,0.4,0.6,1,0.9c0.7,0.4,1.4,1,1.2,2c-0.2,1-1.2,1.7-2.7,1.4C263.1,524.6,262.3,524.1,261.8,523.5z"
							/>
							<path
								d="M268.4,519.5l1.4,0.2l0,0.8l0,0c0.5-0.4,1.1-0.7,1.9-0.6c0.8,0.1,1.3,0.5,1.5,1.2c0.6-0.5,1.2-0.8,2-0.7
					c1.3,0.2,1.7,1.2,1.5,2.6l-0.5,3.6l-1.7-0.2l0.5-3.4c0.1-0.8-0.1-1.1-0.6-1.2c-0.3,0-0.7,0.1-1.1,0.4l-0.6,3.9l-1.7-0.2l0.5-3.4
					c0.1-0.8-0.1-1.1-0.6-1.2c-0.3,0-0.7,0.1-1.1,0.4l-0.6,3.9l-1.7-0.2L268.4,519.5z"
							/>
							<path
								d="M277.8,523.8c0.2-1.9,1.7-2.9,3.1-2.8c1.7,0.2,2.4,1.5,2.2,3.1c0,0.3-0.1,0.6-0.1,0.8l-3.5-0.3c0.1,0.9,0.6,1.3,1.4,1.4
					c0.4,0,0.9-0.1,1.3-0.3l0.5,1.1c-0.6,0.4-1.5,0.5-2.2,0.5C278.8,527,277.7,525.7,277.8,523.8z M281.7,523.5
					c0.1-0.6-0.2-1.1-0.9-1.2c-0.5-0.1-1.1,0.3-1.3,1L281.7,523.5z"
							/>
							<path
								d="M284.6,521.4l1.4,0.1l0.1,0.8l0,0c0.5-0.4,1.1-0.8,1.9-0.8c1.3,0.1,1.8,1,1.7,2.5l-0.2,3.7l-1.7-0.1l0.2-3.4
					c0.1-0.8-0.2-1.1-0.7-1.2c-0.4,0-0.7,0.2-1.1,0.5l-0.3,4l-1.7-0.1L284.6,521.4z"
							/>
							<path
								d="M291.5,525.6l0.1-2.4l-0.8,0l0-1.3l0.9,0l0.3-1.6l1.4,0l-0.1,1.6l1.4,0l0,1.4l-1.4,0l-0.1,2.4c0,0.7,0.3,0.9,0.7,1
					c0.2,0,0.4,0,0.6-0.1l0.2,1.3c-0.3,0.1-0.7,0.2-1.3,0.2C292,527.9,291.4,526.9,291.5,525.6z"
							/>
							<path
								d="M297.7,524.9c0-2,1.4-3.1,2.9-3.1c1.5,0,2.9,1.1,2.9,3.1c0,2-1.4,3.1-2.9,3.1C299.1,528,297.8,526.9,297.7,524.9z
					 M301.7,524.9c0-1-0.4-1.7-1.1-1.7c-0.7,0-1.1,0.7-1.1,1.7c0,1,0.4,1.7,1.1,1.7S301.7,525.9,301.7,524.9z"
							/>
							<path
								d="M304.4,521.9l1.4,0l0.2,1l0,0c0.4-0.8,1-1.2,1.6-1.2c0.3,0,0.5,0,0.7,0.1l-0.2,1.5c-0.2,0-0.4-0.1-0.6-0.1
					c-0.4,0-1,0.3-1.3,1.1l0.1,3.4l-1.7,0.1L304.4,521.9z"
							/>
							<path
								d="M308.9,528.7c0-0.5,0.2-0.9,0.8-1.2l0,0c-0.3-0.2-0.6-0.5-0.6-1c0-0.4,0.2-0.9,0.6-1.1l0,0c-0.4-0.3-0.8-0.8-0.9-1.5
					c-0.1-1.4,1-2.2,2.3-2.3c0.3,0,0.7,0,0.9,0.1l2.2-0.1l0.1,1.3l-0.9,0.1c0.1,0.2,0.2,0.5,0.2,0.8c0.1,1.4-0.9,2-2.2,2.1
					c-0.2,0-0.5,0-0.7-0.1c-0.1,0.1-0.2,0.2-0.2,0.5c0,0.3,0.3,0.4,0.9,0.4l1-0.1c1.4-0.1,2.3,0.3,2.3,1.4c0.1,1.2-1.1,2.2-3.2,2.3
					C310.1,530.1,309,529.7,308.9,528.7z M313,528.1c0-0.4-0.3-0.4-0.9-0.4l-0.6,0c-0.4,0-0.6,0-0.8,0c-0.2,0.2-0.3,0.4-0.3,0.6
					c0,0.4,0.6,0.6,1.3,0.6C312.5,528.9,313,528.5,313,528.1z M312,523.6c0-0.6-0.4-0.9-0.9-0.9c-0.5,0-0.8,0.4-0.7,1
					c0,0.6,0.4,1,0.9,0.9C311.7,524.6,312,524.2,312,523.6z"
							/>
							<path
								d="M314.8,525.7c-0.1-1.2,0.8-2,3.1-2.4c-0.1-0.5-0.4-0.8-1-0.8c-0.5,0-1,0.3-1.5,0.7l-0.7-1.1c0.7-0.5,1.5-0.9,2.4-1
					c1.5-0.1,2.4,0.7,2.6,2.5l0.3,3.4l-1.4,0.1l-0.2-0.6l0,0c-0.5,0.5-1,0.8-1.6,0.9C315.7,527.4,314.9,526.7,314.8,525.7z
					 M318.1,525.3l-0.1-1c-1.2,0.3-1.6,0.7-1.6,1.1c0,0.4,0.3,0.5,0.7,0.5C317.6,525.9,317.9,525.6,318.1,525.3z"
							/>
							<path
								d="M320.8,520.9l1.4-0.2l0.2,0.7l0,0c0.4-0.5,1-1,1.8-1.1c1.3-0.1,1.9,0.7,2.1,2.2l0.4,3.6l-1.7,0.2l-0.4-3.4
					c-0.1-0.8-0.4-1.1-0.9-1c-0.4,0.1-0.7,0.3-1,0.7l0.5,4l-1.7,0.2L320.8,520.9z"
							/>
							<path
								d="M327,518.3c-0.1-0.5,0.3-1,0.9-1c0.6-0.1,1.1,0.2,1.1,0.8c0.1,0.5-0.3,1-0.9,1C327.5,519.2,327.1,518.8,327,518.3z
					 M327.4,520.1l1.7-0.2l0.8,5.8l-1.7,0.2L327.4,520.1z"
							/>
							<path
								d="M330.8,524.9l0.6-1.2c0.6,0.3,1.1,0.5,1.6,0.4c0.5-0.1,0.7-0.3,0.7-0.6c-0.1-0.4-0.7-0.5-1.3-0.6
					c-0.8-0.2-1.7-0.5-1.8-1.5c-0.2-1.1,0.6-2,1.9-2.2c0.9-0.1,1.6,0.1,2.2,0.4l-0.6,1.2c-0.5-0.2-0.9-0.4-1.3-0.3
					c-0.4,0.1-0.6,0.3-0.6,0.6c0.1,0.4,0.6,0.4,1.3,0.6c0.8,0.2,1.7,0.4,1.8,1.5c0.2,1.1-0.5,2-2.1,2.3
					C332.4,525.4,331.4,525.2,330.8,524.9z"
							/>
							<path
								d="M336,523.1c-0.2-1.2,0.6-2.1,2.9-2.7c-0.1-0.5-0.4-0.8-1.1-0.7c-0.5,0.1-0.9,0.4-1.4,0.8l-0.8-1c0.7-0.6,1.4-1,2.3-1.2
					c1.5-0.3,2.5,0.4,2.8,2.2l0.6,3.3l-1.4,0.3l-0.2-0.6l0,0c-0.4,0.5-0.9,0.9-1.6,1C337,524.7,336.2,524,336,523.1z M339.2,522.4
					l-0.2-1c-1.2,0.4-1.5,0.8-1.4,1.2c0.1,0.4,0.4,0.5,0.8,0.4C338.8,523,339,522.8,339.2,522.4z"
							/>
							<path
								d="M342.3,521.4l-0.5-2.3l-0.8,0.2l-0.3-1.3l0.9-0.2l-0.1-1.6l1.4-0.3l0.3,1.5l1.4-0.3l0.3,1.3l-1.4,0.3l0.5,2.3
					c0.1,0.7,0.5,0.9,0.9,0.8c0.2,0,0.4-0.1,0.5-0.2l0.5,1.2c-0.3,0.2-0.7,0.4-1.2,0.5C343.3,523.5,342.5,522.7,342.3,521.4z"
							/>
							<path
								d="M345.1,515.1c-0.1-0.5,0.2-1,0.8-1.1c0.6-0.1,1.1,0.2,1.2,0.7c0.1,0.5-0.2,1-0.8,1.1
					C345.7,515.9,345.2,515.7,345.1,515.1z M345.6,516.8l1.7-0.4l1.3,5.8l-1.7,0.4L345.6,516.8z"
							/>
							<path
								d="M349,519.1c-0.5-1.9,0.6-3.3,2.1-3.7s3.1,0.4,3.5,2.3c0.5,1.9-0.6,3.3-2.1,3.7C351.1,521.8,349.5,521,349,519.1z
					 M352.9,518.2c-0.2-1-0.7-1.6-1.4-1.4c-0.7,0.2-0.9,0.9-0.7,1.9c0.2,1,0.7,1.5,1.4,1.4C352.9,519.9,353.1,519.2,352.9,518.2z"
							/>
							<path
								d="M354.9,514.7l1.4-0.4l0.3,0.7l0,0c0.4-0.6,0.8-1.1,1.6-1.3c1.2-0.3,2,0.4,2.4,1.8l0.9,3.5l-1.7,0.4l-0.9-3.3
					c-0.2-0.8-0.5-1-1-0.9c-0.4,0.1-0.6,0.4-0.9,0.8l1,3.8l-1.7,0.4L354.9,514.7z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Assessment organisation</h4>
					<p>
						The quality of the whole assessment organisation is key to assuring
						the quality of assessment. The assessment organization refers to how
						teachers, boards of examiners, assessment committees, management,
						and support staff work together purposefully to achieve the desired
						assessment quality for all assessment entities (Van Deursen & Van
						Zijl, 2015). It also concerns establishing the roles, tasks and
						responsibilities of the actors/parties involved in assessment,
						cooperation based on defined roles and the logistical organisation
						of assessment.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentliteracy-bg">
						<rect x="50" y="285" width="75" height="150" fillOpacity={0} />
						<g id="assessmentliteracy">
							<path
								d="M79.9,311.8l0,2.1l-7.7,2.5l0-1.9l3.9-1.1c0.8-0.2,1.7-0.4,2.5-0.7v0c-0.8-0.2-1.7-0.4-2.5-0.6l-3.9-1l0-1.8L79.9,311.8
					z M75.3,311l0,3.7l-1.4,0l0-3.7L75.3,311z"
							/>
							<path
								d="M72.9,316.9l1.1,0.7c-0.4,0.6-0.6,1.1-0.5,1.5c0,0.5,0.2,0.7,0.5,0.7c0.4,0,0.5-0.6,0.7-1.2c0.2-0.7,0.7-1.6,1.7-1.6
					c1.1,0,1.9,0.8,1.9,2.2c0,0.9-0.3,1.6-0.7,2.1l-1.1-0.7c0.3-0.4,0.5-0.9,0.4-1.3c0-0.5-0.2-0.7-0.5-0.7c-0.4,0-0.5,0.6-0.7,1.2
					c-0.2,0.8-0.6,1.6-1.7,1.7c-1.1,0-1.9-0.8-2-2.3C72.1,318.4,72.4,317.5,72.9,316.9z"
							/>
							<path
								d="M73.2,322.3l1.1,0.7c-0.4,0.6-0.5,1.1-0.5,1.6c0,0.5,0.3,0.7,0.6,0.7c0.4,0,0.5-0.6,0.7-1.2c0.2-0.7,0.6-1.6,1.6-1.7
					c1.1-0.1,1.9,0.7,2,2.1c0.1,0.9-0.2,1.6-0.6,2.1l-1.1-0.7c0.3-0.4,0.4-0.9,0.4-1.3c0-0.5-0.2-0.7-0.5-0.6
					c-0.4,0-0.5,0.6-0.7,1.2c-0.2,0.8-0.6,1.6-1.6,1.7c-1.1,0.1-2-0.7-2.1-2.3C72.5,323.8,72.7,322.9,73.2,322.3z"
							/>
							<path
								d="M75.9,327.6c1.9-0.2,3.2,1,3.4,2.4c0.2,1.7-0.9,2.6-2.5,2.8c-0.3,0-0.6,0-0.8,0l-0.4-3.5c-0.8,0.2-1.2,0.9-1.1,1.7
					c0,0.4,0.2,0.8,0.5,1.2l-1,0.7c-0.5-0.6-0.8-1.3-0.9-2C72.9,329.2,73.9,327.8,75.9,327.6z M76.9,331.3c0.6-0.1,1.1-0.4,1-1.1
					c-0.1-0.5-0.5-1-1.2-1L76.9,331.3z"
							/>
							<path
								d="M74.3,334l1.2,0.6c-0.3,0.6-0.5,1.1-0.4,1.6c0.1,0.5,0.3,0.7,0.6,0.7c0.4-0.1,0.5-0.7,0.6-1.3c0.2-0.8,0.5-1.7,1.5-1.8
					c1.1-0.2,2,0.6,2.1,2c0.1,0.9-0.1,1.6-0.4,2.2l-1.2-0.6c0.2-0.5,0.4-0.9,0.3-1.3c-0.1-0.4-0.3-0.6-0.6-0.6
					c-0.4,0.1-0.4,0.6-0.6,1.3c-0.2,0.8-0.5,1.7-1.5,1.8c-1.1,0.2-2-0.6-2.2-2.1C73.7,335.5,73.9,334.6,74.3,334z"
							/>
							<path
								d="M75.1,339.3l1.2,0.6c-0.3,0.6-0.4,1.1-0.4,1.6c0.1,0.5,0.3,0.7,0.6,0.6c0.4-0.1,0.5-0.7,0.6-1.3
					c0.2-0.8,0.5-1.7,1.4-1.8c1.1-0.2,2,0.6,2.2,1.9c0.2,0.9-0.1,1.6-0.4,2.2l-1.2-0.6c0.2-0.5,0.4-0.9,0.3-1.3
					c-0.1-0.4-0.3-0.6-0.6-0.6c-0.4,0.1-0.4,0.6-0.5,1.3c-0.1,0.8-0.4,1.7-1.5,1.9c-1.1,0.2-2-0.5-2.3-2.1
					C74.5,340.9,74.7,339.9,75.1,339.3z"
							/>
							<path
								d="M81.2,344.1l0.3,1.4l-0.7,0.3l0,0c0.6,0.4,1.1,0.8,1.3,1.6c0.2,0.8-0.1,1.4-0.6,1.8c0.6,0.4,1.2,0.8,1.4,1.6
					c0.3,1.2-0.5,2-1.9,2.3l-3.6,0.8l-0.4-1.7l3.4-0.7c0.8-0.2,1-0.5,0.9-1c-0.1-0.3-0.3-0.6-0.8-0.9l-3.9,0.9l-0.4-1.7l3.4-0.7
					c0.8-0.2,1-0.5,0.9-1c-0.1-0.3-0.3-0.6-0.8-0.9l-3.9,0.9l-0.4-1.7L81.2,344.1z"
							/>
							<path
								d="M80.5,354.4c1.9-0.5,3.3,0.5,3.7,1.9c0.4,1.6-0.5,2.7-2.1,3.2c-0.3,0.1-0.6,0.1-0.8,0.1l-0.9-3.4c-0.8,0.4-1,1-0.8,1.8
					c0.1,0.4,0.3,0.8,0.7,1.1l-0.9,0.8c-0.6-0.5-1-1.2-1.2-1.9C77.8,356.4,78.6,354.9,80.5,354.4z M82.1,357.9
					c0.6-0.2,1-0.6,0.8-1.2c-0.1-0.5-0.6-0.9-1.4-0.8L82.1,357.9z"
							/>
							<path
								d="M85,359.9l0.4,1.4l-0.7,0.3l0,0c0.6,0.3,1.2,0.8,1.4,1.6c0.4,1.2-0.3,2-1.7,2.4l-3.5,1.1l-0.5-1.7l3.3-1
					c0.8-0.2,1-0.5,0.9-1c-0.1-0.4-0.4-0.6-0.9-0.9l-3.8,1.2l-0.5-1.7L85,359.9z"
							/>
							<path
								d="M83.5,367.8l2.3-0.8l-0.3-0.8l1.2-0.4l0.4,0.8l1.6-0.3l0.5,1.4l-1.5,0.5l0.5,1.3l-1.3,0.4l-0.5-1.3l-2.2,0.8
					c-0.6,0.2-0.8,0.6-0.6,1c0.1,0.2,0.2,0.4,0.3,0.5l-1.1,0.7c-0.2-0.3-0.4-0.6-0.6-1.2C81.6,369.1,82.2,368.2,83.5,367.8z"
							/>
							<path
								d="M85.4,374.2l6.1-2.3l0.6,1.6l-6.2,2.3c-0.4,0.1-0.4,0.3-0.4,0.5c0,0.1,0,0.1,0.1,0.2l-1.1,0.6c-0.1-0.2-0.3-0.4-0.4-0.8
					C83.7,375.3,84.3,374.6,85.4,374.2z"
							/>
							<path
								d="M90.4,375.8l0.6,1.6l-5.5,2.1l-0.6-1.6L90.4,375.8z M92,375c0.5-0.2,1,0.1,1.2,0.6c0.2,0.5,0,1.1-0.5,1.3
					c-0.5,0.2-1-0.1-1.2-0.6C91.3,375.8,91.5,375.2,92,375z"
							/>
							<path
								d="M88.2,380.4l2.2-0.9l-0.3-0.7l1.2-0.5l0.4,0.8l1.5-0.4l0.6,1.3l-1.4,0.6l0.5,1.3l-1.3,0.5l-0.5-1.3l-2.2,0.9
					c-0.6,0.3-0.7,0.6-0.6,1.1c0.1,0.2,0.2,0.4,0.3,0.5l-1.1,0.7c-0.2-0.3-0.5-0.6-0.7-1.1C86.3,381.8,86.9,380.9,88.2,380.4z"
							/>
							<path
								d="M90.3,383.5c1.8-0.8,3.4,0,4,1.3c0.7,1.5-0.1,2.8-1.6,3.4c-0.3,0.1-0.6,0.2-0.7,0.2l-1.4-3.2c-0.7,0.5-0.8,1.2-0.5,1.9
					c0.2,0.4,0.5,0.7,0.9,1l-0.7,1c-0.6-0.4-1.2-1-1.5-1.6C88,385.9,88.5,384.2,90.3,383.5z M92.4,386.7c0.6-0.3,0.9-0.7,0.6-1.3
					c-0.2-0.5-0.8-0.8-1.5-0.6L92.4,386.7z"
							/>
							<path
								d="M95.6,388l0.6,1.3l-0.9,0.6l0,0c0.9,0.1,1.5,0.5,1.8,1c0.1,0.3,0.2,0.5,0.2,0.7l-1.5,0.4c0-0.2-0.1-0.4-0.2-0.6
					c-0.2-0.4-0.7-0.8-1.5-0.7l-3.1,1.5l-0.7-1.6L95.6,388z"
							/>
							<path
								d="M93.4,393.5c1.1-0.6,2.2,0,3.4,2c0.4-0.3,0.6-0.6,0.3-1.2c-0.2-0.4-0.6-0.8-1.2-1.2l0.8-1.1c0.7,0.5,1.4,1.1,1.8,1.9
					c0.7,1.3,0.3,2.5-1.3,3.3l-3,1.5l-0.6-1.3l0.5-0.4l0,0c-0.6-0.2-1.1-0.6-1.4-1.2C92.2,394.9,92.5,393.9,93.4,393.5z M95,396.4
					l0.9-0.5c-0.7-1-1.2-1.2-1.6-1c-0.3,0.2-0.4,0.5-0.2,0.9C94.3,396.1,94.6,396.3,95,396.4z"
							/>
							<path
								d="M97.3,398.3c1.7-0.9,3.4-0.2,4.2,1.3c0.3,0.6,0.4,1.3,0.3,1.8l-1.4-0.2c0.1-0.4,0.1-0.6-0.1-0.9
					c-0.4-0.7-1.2-0.9-2.1-0.4c-0.9,0.5-1.2,1.3-0.9,2c0.2,0.3,0.5,0.6,0.9,0.7l-0.7,1.1c-0.7-0.3-1.2-0.8-1.5-1.4
					C95.2,400.9,95.6,399.2,97.3,398.3z"
							/>
							<path
								d="M95.5,405.8l1.3-0.4c0,0.1,0.1,0.3,0.1,0.4c0.3,0.5,0.7,0.6,1.2,0.5l0.3-0.1l3.9-4.8l0.9,1.5l-1.8,2
					c-0.4,0.4-0.8,0.8-1.2,1.2l0,0c0.6-0.2,1.1-0.3,1.7-0.5l2.6-0.6l0.8,1.5l-6.2,1.2c-1.6,0.2-2.5,0.1-3.2-1.1
					C95.7,406.3,95.6,406.1,95.5,405.8z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Assessment literacy</h4>
					<p>
						In the assessment web, ‘assessment literacy’ refers to the expertise
						actors within the study programme should have to achieve quality
						across all assessment entities (Van Berkel, Sluijsmans & Joosten-ten
						Brinke, 2015). Specifically for the entity ’assessment’, the
						assessment literacy of teachers, boards of examiners and other
						involved parties is of utmost importance. Every teacher must be able
						to interpret assessment information and use it to establish how a
						student is doing and how this information can further contribute to
						the student’s learning (Straetmans, 2006).
					</p>
				</div>
			</Portal>
		</g>
	)
}

export const ToetsmodelSideTextNL = () => {
	return (
		<g id="side-texts">
			<Portal
				trigger={
					<g id="assessmenttasks-bg">
						<rect x="120" y="80" fillOpacity={0} width="120" height="120" />
						<g id="assessmenttasks">
							<path d="M140.3,132.6l-1.5,1.5l-1-1.1l4.4-4.1l1,1.1l-1.5,1.5l4.3,4.6l-1.3,1.2L140.3,132.6z" />
							<path
								d="M146,131.8c-1.3-1.5-1-3.2,0.1-4.2s2.9-1.1,4.2,0.4c1.3,1.5,1,3.2-0.1,4.2C149.1,133.2,147.3,133.3,146,131.8z
						 M148.9,129.2c-0.7-0.8-1.4-1-1.9-0.5s-0.4,1.2,0.3,2c0.7,0.8,1.4,1,1.9,0.5C149.8,130.7,149.6,129.9,148.9,129.2z"
							/>
							<path
								d="M150.9,127.4c-1.2-1.5-1-3.2,0.1-4.2c1.3-1.1,2.7-0.7,3.7,0.5c0.2,0.2,0.4,0.5,0.4,0.6l-2.7,2.3c0.7,0.6,1.4,0.5,2,0
						c0.3-0.3,0.6-0.7,0.7-1.1l1.1,0.4c-0.2,0.7-0.6,1.4-1.2,1.9C153.9,128.9,152.2,128.9,150.9,127.4z M153.4,124.5
						c-0.4-0.5-0.9-0.7-1.5-0.2c-0.4,0.4-0.5,0.9-0.2,1.6L153.4,124.5z"
							/>
							<path
								d="M156.6,123.7l-1.5-1.9l-0.6,0.5l-0.8-1l0.7-0.6l-0.8-1.3l1.1-0.9l1,1.2l1.1-0.9l0.9,1.1l-1.1,0.9l1.5,1.8
						c0.4,0.5,0.8,0.5,1.2,0.3c0.2-0.1,0.3-0.3,0.4-0.4l1,0.8c-0.2,0.3-0.4,0.6-0.9,1C158.5,125.1,157.5,124.8,156.6,123.7z"
							/>
							<path
								d="M160.5,122.5l0-1.3c0.7,0,1.2-0.1,1.6-0.4c0.4-0.3,0.5-0.6,0.3-0.9c-0.2-0.3-0.8-0.1-1.4,0.1c-0.7,0.2-1.7,0.3-2.3-0.4
						c-0.7-0.9-0.4-2,0.7-2.8c0.7-0.6,1.5-0.7,2.1-0.7l0,1.3c-0.5,0-1,0.1-1.3,0.4c-0.4,0.3-0.4,0.6-0.2,0.8
						c0.2,0.3,0.8,0.1,1.4-0.1c0.8-0.2,1.7-0.4,2.3,0.4c0.7,0.8,0.5,2-0.8,3C162.2,122.2,161.3,122.5,160.5,122.5z"
							/>
							<path
								d="M163.6,116.8c-1.2-1.7-1.8-3.4-1.9-5.3l1.2-0.3c0.2,1.8,0.9,3.4,1.9,4.8c1,1.4,2.3,2.5,4,3.3l-0.6,1
						C166.3,119.6,164.8,118.5,163.6,116.8z"
							/>
							<path
								d="M167.7,115.3l-1.4-1.9l-0.7,0.5l-0.8-1.1l0.7-0.6l-0.7-1.4l1.2-0.8l0.9,1.3l1.2-0.8l0.8,1.1l-1.2,0.8l1.4,1.9
						c0.4,0.5,0.8,0.6,1.2,0.3c0.2-0.1,0.3-0.3,0.4-0.4l1,0.9c-0.2,0.3-0.5,0.6-0.9,0.9C169.5,116.8,168.5,116.4,167.7,115.3z"
							/>
							<path
								d="M171.3,113.4c-0.7-1-0.2-2.1,1.6-3.6c-0.3-0.4-0.7-0.5-1.2-0.2c-0.4,0.3-0.7,0.7-1,1.3l-1.2-0.6c0.4-0.8,0.9-1.5,1.7-2
						c1.3-0.8,2.4-0.6,3.4,0.9l1.9,2.8l-1.2,0.8l-0.4-0.4l0,0c-0.2,0.6-0.5,1.2-1,1.6C172.9,114.5,171.9,114.2,171.3,113.4z
						 M174,111.4l-0.6-0.9c-0.9,0.8-1.1,1.3-0.8,1.7c0.2,0.3,0.5,0.3,0.9,0.1C173.8,112.2,173.9,111.9,174,111.4z"
							/>
							<path
								d="M173.2,104.1l1.4-0.9l2.5,4l0,0l0.3-3l1.6-1l-0.5,3.2l3.7,1.8l-1.6,1l-2.3-1.2l-0.2,1.1l0.8,1.2l-1.4,0.9L173.2,104.1z
						"
							/>
							<path
								d="M183.8,109.3c0-1.8-0.6-3.5-1.5-4.9c-0.9-1.5-2.1-2.8-3.6-3.6l0.7-1c1.7,0.9,3.1,2.1,4.1,3.9c1.1,1.8,1.5,3.6,1.4,5.5
						L183.8,109.3z"
							/>
							<path
								d="M184.8,103.3c-0.9-1.7-0.3-3.4,0.9-4.1c1.5-0.8,2.8-0.2,3.6,1.2c0.2,0.3,0.3,0.6,0.3,0.7l-3.1,1.7
						c0.6,0.7,1.3,0.7,1.9,0.3c0.4-0.2,0.7-0.5,0.9-1l1,0.6c-0.3,0.7-0.9,1.3-1.5,1.6C187.5,105.3,185.8,105,184.8,103.3z
						 M187.8,100.8c-0.3-0.6-0.8-0.8-1.4-0.5c-0.5,0.3-0.7,0.8-0.5,1.5L187.8,100.8z"
							/>
							<path
								d="M189.1,97.5l1.3-0.7l0.5,0.6l0,0c0.2-0.7,0.5-1.3,1.3-1.7c1.1-0.6,2-0.1,2.7,1.3l1.7,3.2l-1.5,0.8l-1.6-3
						c-0.4-0.8-0.7-0.9-1.2-0.6c-0.4,0.2-0.5,0.5-0.7,1l1.9,3.5l-1.5,0.8L189.1,97.5z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Toets(tak)en</h4>
					<p>
						Toetsen zijn (leer)activiteiten/meet-instrumenten die worden ingezet
						om na te gaan of de beoogde leerresultaten zijn bereikt (Joosten-ten
						Brinke & Draaier, 2015). Bij de kwaliteit van de toets in zijn
						geheel horen ook het ontwerp van de beoordelingsmodellen en student-
						en beoordelaarsinstructies. Toetstaken zijn items of opdrachten
						binnen een toets waarmee studenten worden uitgedaagd hun kennis en
						vaardigheden te tonen (Draaier & Joosten-ten Brinke, 2015.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentprogram-bg">
						<rect x="380" y="80" fillOpacity={0} width="120" height="120" />
						<g id="assessmentprogram">
							<path d="M397.9,92.8l-1.9-0.9l0.7-1.3l5.4,2.7l-0.7,1.3l-1.9-0.9l-2.8,5.6l-1.6-0.8L397.9,92.8z" />
							<path
								d="M400.6,97.9c0.9-1.7,2.7-2.1,4-1.4c1.3,0.7,2,2.4,1.1,4.1c-0.9,1.7-2.7,2.1-4,1.4C400.3,101.2,399.6,99.6,400.6,97.9z
						 M404.1,99.7c0.5-0.9,0.5-1.7-0.2-2c-0.6-0.3-1.3,0.1-1.7,1c-0.5,0.9-0.5,1.7,0.2,2C403,101,403.6,100.6,404.1,99.7z"
							/>
							<path
								d="M406.4,101c0.9-1.7,2.7-2,3.9-1.3c1.5,0.8,1.6,2.3,0.8,3.7c-0.2,0.3-0.3,0.5-0.4,0.6l-3.1-1.7c-0.3,0.8,0,1.5,0.7,1.8
						c0.4,0.2,0.8,0.3,1.3,0.3l0,1.2c-0.7,0.1-1.5-0.1-2.1-0.5C406,104.3,405.4,102.7,406.4,101z M410,102.3
						c0.3-0.6,0.3-1.1-0.3-1.5c-0.5-0.3-1.1-0.2-1.5,0.4L410,102.3z"
							/>
							<path
								d="M411.8,105.1l1.2-2l-0.7-0.4l0.7-1.1l0.8,0.4l1-1.2l1.2,0.7l-0.8,1.3l1.2,0.7l-0.7,1.2l-1.2-0.7l-1.2,2
						c-0.3,0.6-0.2,1,0.2,1.2c0.2,0.1,0.4,0.2,0.5,0.2l-0.4,1.2c-0.3-0.1-0.7-0.2-1.2-0.5C411.2,107.3,411.1,106.2,411.8,105.1z"
							/>
							<path
								d="M414.3,108.3l1.2-0.5c0.2,0.6,0.5,1.1,1,1.3c0.4,0.3,0.7,0.2,0.9,0c0.2-0.3-0.2-0.8-0.6-1.3c-0.5-0.6-0.9-1.5-0.4-2.3
						c0.6-0.9,1.7-1.1,2.9-0.4c0.8,0.5,1.1,1.2,1.4,1.7l-1.2,0.5c-0.2-0.5-0.4-0.9-0.8-1.1c-0.4-0.2-0.7-0.2-0.8,0
						c-0.2,0.3,0.2,0.7,0.6,1.2c0.5,0.6,1,1.4,0.4,2.3c-0.6,0.9-1.7,1.2-3.1,0.3C415.1,109.7,414.6,109,414.3,108.3z"
							/>
							<path
								d="M422.1,107l1.2,0.8l-0.2,0.6l0,0c0.6-0.1,1.3,0,1.8,0.3c1.2,0.8,1.3,2.3,0.2,3.8c-1.1,1.7-2.8,2-3.8,1.3
						c-0.4-0.3-0.7-0.7-0.8-1.3l-0.5,0.8l-1,1.4l-1.5-1L422.1,107z M423.6,111.5c0.6-0.9,0.6-1.5,0-1.9c-0.3-0.2-0.6-0.2-1.1-0.1
						l-1.3,2c0.1,0.4,0.3,0.7,0.6,0.8C422.2,112.7,422.9,112.5,423.6,111.5z"
							/>
							<path
								d="M427.8,110.9l1.2,0.8l-0.5,0.9l0,0c0.8-0.4,1.6-0.3,2,0c0.3,0.2,0.4,0.3,0.5,0.5l-1.1,1.1c-0.1-0.2-0.3-0.3-0.5-0.4
						c-0.4-0.3-1-0.3-1.7,0.1l-2,2.8l-1.4-1L427.8,110.9z"
							/>
							<path
								d="M429.7,115.8c1.2-1.6,3-1.7,4.2-0.8c1.2,0.9,1.6,2.6,0.5,4.2c-1.2,1.6-2.9,1.7-4.1,0.8S428.5,117.4,429.7,115.8z
						 M432.9,118.2c0.6-0.8,0.7-1.6,0.1-2c-0.6-0.4-1.3-0.1-1.9,0.7c-0.6,0.8-0.7,1.6-0.1,2S432.2,119,432.9,118.2z"
							/>
							<path
								d="M432.5,122.9c0.3-0.4,0.8-0.5,1.4-0.4l0,0c-0.1-0.4-0.1-0.7,0.2-1.1c0.3-0.3,0.7-0.5,1.2-0.5l0,0
						c-0.1-0.5-0.1-1.2,0.3-1.7c0.9-1.1,2.2-0.9,3.2-0.2c0.3,0.2,0.5,0.4,0.6,0.7l1.7,1.3l-0.8,1l-0.7-0.6c0,0.2-0.1,0.5-0.3,0.7
						c-0.8,1.1-2,0.9-3.1,0.1c-0.2-0.1-0.3-0.3-0.5-0.5c-0.2,0-0.3,0.1-0.4,0.2c-0.2,0.2-0.1,0.5,0.4,0.9l0.8,0.6
						c1.1,0.9,1.5,1.7,0.8,2.6c-0.8,1-2.3,0.9-3.9-0.4C432.5,124.8,431.9,123.7,432.5,122.9z M435.9,125.2c0.2-0.3,0-0.6-0.4-0.9
						l-0.5-0.4c-0.3-0.3-0.5-0.4-0.6-0.6c-0.3,0-0.5,0.1-0.6,0.3c-0.3,0.4,0,0.9,0.6,1.3C435,125.4,435.7,125.5,435.9,125.2z
						 M438.3,121.2c0.4-0.5,0.3-1,0-1.3c-0.4-0.3-0.8-0.2-1.2,0.3c-0.4,0.5-0.3,1,0,1.3C437.4,121.7,437.9,121.7,438.3,121.2z"
							/>
							<path
								d="M442.1,121.7l1.1,0.9l-0.6,0.9l0,0c0.8-0.3,1.6-0.2,2,0.2c0.3,0.2,0.4,0.4,0.5,0.5l-1.2,1c-0.1-0.2-0.2-0.3-0.4-0.5
						c-0.3-0.3-0.9-0.4-1.7,0l-2.2,2.6l-1.3-1.1L442.1,121.7z"
							/>
							<path
								d="M442.8,127.9c0.8-0.9,2-0.8,3.9,0.6c0.3-0.4,0.3-0.8-0.1-1.3c-0.4-0.3-0.9-0.5-1.5-0.6l0.3-1.3
						c0.9,0.1,1.7,0.5,2.4,1.1c1.1,1,1.2,2.2,0,3.6l-2.2,2.5l-1.1-0.9l0.3-0.5l0,0c-0.7,0-1.3-0.1-1.8-0.6
						C442.2,129.7,442.2,128.6,442.8,127.9z M445.4,130l0.7-0.8c-1-0.7-1.6-0.7-1.9-0.4c-0.2,0.3-0.2,0.6,0.1,0.9
						C444.6,130,445,130,445.4,130z"
							/>
							<path
								d="M450.7,129.1l1,1l-0.4,0.6l0,0c0.7,0,1.4,0,1.9,0.6c0.6,0.6,0.7,1.2,0.5,1.8c0.7,0,1.5,0,2,0.5c0.9,0.9,0.7,1.9-0.3,3
						l-2.5,2.7l-1.3-1.2l2.4-2.5c0.6-0.6,0.6-1,0.2-1.3c-0.2-0.2-0.6-0.3-1.1-0.3l-2.7,2.9l-1.3-1.2l2.4-2.5c0.6-0.6,0.6-1,0.2-1.3
						c-0.2-0.2-0.6-0.3-1.1-0.3l-2.7,2.9l-1.3-1.2L450.7,129.1z"
							/>
							<path
								d="M458.2,136.2l1,1l-0.5,0.6l0,0c0.7,0,1.4,0.1,1.9,0.7c0.6,0.6,0.7,1.2,0.4,1.9c0.8,0,1.5,0.1,2,0.6
						c0.9,0.9,0.6,2-0.4,3l-2.6,2.6l-1.2-1.3l2.5-2.4c0.6-0.6,0.6-0.9,0.3-1.3c-0.2-0.2-0.6-0.3-1.1-0.3l-2.9,2.8l-1.2-1.3l2.5-2.4
						c0.6-0.6,0.6-0.9,0.3-1.3c-0.2-0.2-0.6-0.3-1.1-0.3l-2.9,2.8l-1.2-1.3L458.2,136.2z"
							/>
							<path
								d="M462,146.3c0.9-0.8,2.1-0.5,3.8,1c0.4-0.4,0.4-0.8,0-1.3c-0.3-0.4-0.8-0.6-1.4-0.8l0.4-1.2c0.8,0.2,1.6,0.7,2.3,1.4
						c1,1.1,0.9,2.3-0.4,3.5l-2.5,2.3l-1-1.1l0.4-0.5l0,0c-0.7-0.1-1.3-0.3-1.7-0.8C461.1,148,461.3,147,462,146.3z M464.3,148.7
						l0.8-0.7c-1-0.8-1.5-0.9-1.8-0.6c-0.3,0.3-0.2,0.6,0,0.9C463.5,148.6,463.9,148.7,464.3,148.7z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Toetsprogramma</h4>
					<p>
						Het toetsprogramma is een bewuste en beargumenteerde combinatie van
						toets(vorm)en, passend bij de doelen en opbouw van een opleiding
						(het onderwijsprogramma) (Baartman & Van der Vleuten, 2015). Het
						gaat om de heldere samenhang tussen toetsen en de borging van de
						verschillende functies van toetsing.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentpolicy-bg">
						<rect x="450" y="300" width="120" height="120" fillOpacity={0} />
						<g id="assessmentpolicy">
							<path d="M523.7,327.7l0.3-2.1l1.5,0.2l-0.8,6l-1.5-0.2l0.3-2.1l-6.2-0.8l0.2-1.7L523.7,327.7z" />
							<path
								d="M519.8,331.8c1.9,0.3,2.8,1.9,2.6,3.3c-0.2,1.5-1.6,2.7-3.5,2.3c-1.9-0.3-2.8-1.9-2.6-3.3
						C516.5,332.7,517.9,331.5,519.8,331.8z M519.1,335.7c1,0.2,1.7-0.1,1.8-0.8c0.1-0.7-0.5-1.2-1.5-1.3c-1-0.2-1.7,0.1-1.8,0.8
						S518.1,335.6,519.1,335.7z"
							/>
							<path
								d="M518.7,338.4c1.9,0.4,2.8,1.9,2.5,3.3c-0.3,1.6-1.7,2.2-3.3,1.9c-0.3-0.1-0.6-0.2-0.7-0.2l0.7-3.5
						c-0.9,0-1.4,0.5-1.5,1.3c-0.1,0.4,0,0.9,0.1,1.3l-1.1,0.4c-0.3-0.7-0.4-1.5-0.3-2.2C515.4,339.1,516.8,338,518.7,338.4z
						 M518.6,342.2c0.6,0.1,1.1-0.1,1.3-0.7c0.1-0.5-0.2-1.1-0.9-1.3L518.6,342.2z"
							/>
							<path
								d="M516.6,344.8l2.3,0.5l0.2-0.8l1.3,0.3l-0.1,0.9l1.5,0.5l-0.3,1.4l-1.5-0.3l-0.3,1.4l-1.3-0.3l0.3-1.4l-2.3-0.5
						c-0.7-0.1-1,0.1-1.1,0.5c0,0.2,0,0.4,0,0.6l-1.3,0c0-0.3-0.1-0.8,0.1-1.3C514.2,344.9,515.2,344.5,516.6,344.8z"
							/>
							<path
								d="M514.3,348.2l0.9,1c-0.5,0.4-0.8,0.9-1,1.3c-0.1,0.5,0,0.8,0.3,0.8c0.4,0.1,0.7-0.4,1.1-1c0.4-0.6,1.1-1.3,2.1-1.1
						c1.1,0.3,1.6,1.3,1.3,2.6c-0.2,0.9-0.7,1.4-1.2,1.8l-0.8-1c0.4-0.3,0.7-0.7,0.8-1.1c0.1-0.4,0-0.7-0.3-0.8
						c-0.4-0.1-0.6,0.4-1,1c-0.4,0.7-1.1,1.4-2.1,1.1c-1-0.3-1.6-1.3-1.3-2.8C513.2,349.4,513.7,348.7,514.3,348.2z"
							/>
							<path
								d="M512.5,355.3L512.5,355.3l-0.6-0.3l0.4-1.3l8,2.2l-0.5,1.7l-2-0.5l-0.9-0.3c0.3,0.5,0.4,1.1,0.2,1.7
						c-0.4,1.4-1.7,1.9-3.5,1.4c-2-0.5-2.8-2-2.4-3.2C511.5,356,511.9,355.6,512.5,355.3z M514.2,358.1c1,0.3,1.6,0.1,1.8-0.6
						c0.1-0.3,0-0.7-0.2-1.1l-2.3-0.6c-0.3,0.2-0.5,0.5-0.6,0.8C512.7,357.1,513,357.7,514.2,358.1z"
							/>
							<path
								d="M513.4,360.6c1.8,0.6,2.6,2.2,2.1,3.6c-0.5,1.6-1.9,2-3.4,1.6c-0.3-0.1-0.6-0.2-0.7-0.3l1-3.4
						c-0.9-0.1-1.4,0.3-1.6,1.1c-0.1,0.4-0.1,0.9,0,1.3l-1.2,0.2c-0.2-0.7-0.2-1.5,0-2.2C510.1,361,511.5,360.1,513.4,360.6z
						 M512.9,364.5c0.6,0.2,1.1,0.1,1.4-0.6c0.2-0.5-0.1-1.1-0.7-1.4L512.9,364.5z"
							/>
							<path
								d="M510.4,366.5l6.2,2l-0.5,1.7l-6.2-2c-0.4-0.1-0.5,0-0.6,0.1c0,0.1,0,0.1,0,0.2l-1.3-0.2c0-0.2,0-0.5,0.1-0.9
						C508.4,366.3,509.3,366.1,510.4,366.5z"
							/>
							<path
								d="M510.5,369.8c1.8,0.6,2.5,2.3,2,3.7c-0.6,1.6-2,2-3.5,1.4c-0.3-0.1-0.6-0.2-0.7-0.3l1.2-3.3c-0.9-0.1-1.4,0.3-1.7,1
						c-0.1,0.4-0.2,0.8-0.1,1.3l-1.2,0.2c-0.2-0.7-0.2-1.5,0.1-2.2C507.2,370,508.7,369.1,510.5,369.8z M509.9,373.6
						c0.6,0.2,1.1,0.1,1.4-0.6c0.2-0.5,0-1.1-0.7-1.5L509.9,373.6z"
							/>
							<path
								d="M511.1,377l-0.6,1.6l-5.5-2.1l0.6-1.6L511.1,377z M512.8,377.5c0.5,0.2,0.7,0.7,0.5,1.3s-0.7,0.8-1.2,0.6
						c-0.5-0.2-0.7-0.7-0.5-1.3C511.8,377.6,512.3,377.3,512.8,377.5z"
							/>
							<path
								d="M507.3,378.7c1.8,0.7,2.4,2.3,2,3.4c-0.2,0.6-0.6,0.9-1.1,1.1l0.9,0.3l1.9,0.8l-0.7,1.6l-7.7-3.1l0.5-1.3l0.6,0.1l0,0
						c-0.2-0.5-0.3-1.2-0.1-1.7C504.1,378.5,505.5,378,507.3,378.7z M504.8,381.9l2.2,0.9c0.4-0.2,0.6-0.5,0.7-0.8
						c0.2-0.5-0.1-1.2-1.1-1.6c-1.1-0.4-1.7-0.3-2,0.4C504.5,381.1,504.5,381.5,504.8,381.9z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Toetsbeleid</h4>
					<p>
						Met het toetsbeleid wordt het geheel aan vastgestelde afspraken,
						zowel inhoudelijk als procedureel, over toetsen en beoordelen
						bedoeld (Bruijns & Kok, 2015). Binnen het integraal toetsbeleid zijn
						meerdere niveaus te onderscheiden, waaronder het beleid op
						instellingsniveau en het beleid op opleidingsniveau.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="toetsorganisatie-bg">
						<rect x="225" y="500" width="150" height="50" fillOpacity={0} />
						<g id="toetsorganisatie">
							<path d="M259.5,515.1l-2.1-0.5l0.3-1.4l5.9,1.3l-0.3,1.4l-2.1-0.5l-1.4,6.1l-1.7-0.4L259.5,515.1z" />
							<path
								d="M263.4,519.3c0.4-1.9,1.9-2.8,3.4-2.5c1.5,0.3,2.6,1.6,2.3,3.6c-0.4,1.9-1.9,2.8-3.4,2.5
						C264.2,522.6,263,521.2,263.4,519.3z M267.2,520c0.2-1,0-1.7-0.8-1.9c-0.7-0.1-1.2,0.5-1.4,1.5c-0.2,1,0,1.7,0.8,1.8
						C266.6,521.6,267.1,521,267.2,520z"
							/>
							<path
								d="M269.9,520.5c0.3-1.9,1.8-2.9,3.2-2.7c1.7,0.2,2.3,1.6,2.1,3.2c0,0.3-0.1,0.6-0.2,0.8l-3.5-0.5c0,0.9,0.6,1.3,1.3,1.5
						c0.4,0.1,0.9,0,1.3-0.2l0.4,1.1c-0.7,0.3-1.5,0.5-2.2,0.4C270.8,523.8,269.7,522.4,269.9,520.5z M273.8,520.4
						c0.1-0.6-0.1-1.1-0.8-1.2c-0.5-0.1-1.1,0.2-1.3,0.9L273.8,520.4z"
							/>
							<path
								d="M276.6,522.2l0.3-2.4l-0.8-0.1l0.1-1.3l0.9,0l0.4-1.5l1.4,0.2l-0.2,1.6l1.4,0.2l-0.2,1.4l-1.4-0.2l-0.3,2.3
						c-0.1,0.7,0.2,1,0.7,1c0.2,0,0.4,0,0.6,0l0.1,1.3c-0.3,0.1-0.8,0.1-1.3,0.1C276.9,524.6,276.4,523.6,276.6,522.2z"
							/>
							<path
								d="M280.3,524.1l0.9-1c0.5,0.4,1,0.7,1.5,0.7c0.5,0,0.8-0.1,0.8-0.4c0-0.4-0.5-0.6-1.1-0.9c-0.7-0.3-1.5-0.9-1.4-1.9
						c0.1-1.1,1-1.8,2.4-1.6c0.9,0.1,1.5,0.5,2,0.9l-0.9,1c-0.4-0.3-0.8-0.6-1.2-0.6c-0.5,0-0.7,0.1-0.7,0.4c0,0.4,0.5,0.6,1.1,0.8
						c0.7,0.3,1.5,0.8,1.4,1.9c-0.1,1.1-1,1.8-2.6,1.7C281.7,525.1,280.8,524.7,280.3,524.1z"
							/>
							<path
								d="M286,522.4c0.1-2,1.5-3,3-2.9c1.5,0.1,2.8,1.3,2.7,3.2c-0.1,2-1.5,3-3,2.9C287.2,525.5,285.9,524.3,286,522.4z
						 M289.9,522.6c0.1-1-0.3-1.7-1-1.7c-0.7,0-1.1,0.6-1.2,1.6c-0.1,1,0.3,1.7,1,1.7C289.5,524.2,289.9,523.6,289.9,522.6z"
							/>
							<path
								d="M293,519.7l1.4,0l0.1,1l0,0c0.4-0.8,1.1-1.2,1.7-1.1c0.3,0,0.5,0.1,0.7,0.1l-0.3,1.5c-0.2-0.1-0.4-0.1-0.6-0.1
						c-0.4,0-1,0.3-1.3,1.1l-0.1,3.4l-1.7,0L293,519.7z"
							/>
							<path
								d="M297.5,526.7c0-0.5,0.3-0.9,0.8-1.2l0,0c-0.3-0.2-0.5-0.5-0.5-1c0-0.4,0.3-0.8,0.6-1.1l0,0c-0.4-0.3-0.8-0.8-0.8-1.5
						c0-1.4,1.1-2.1,2.4-2.2c0.3,0,0.6,0,0.9,0.1l2.2,0l0,1.3l-0.9,0c0.1,0.2,0.2,0.5,0.2,0.8c0,1.4-1,2-2.3,2c-0.2,0-0.5,0-0.7-0.1
						c-0.1,0.1-0.2,0.2-0.2,0.4c0,0.3,0.2,0.4,0.9,0.4l1,0c1.4,0,2.3,0.4,2.3,1.5c0,1.2-1.3,2.1-3.3,2.2
						C298.6,528.2,297.5,527.8,297.5,526.7z M301.6,526.4c0-0.4-0.3-0.5-0.9-0.4l-0.6,0c-0.4,0-0.6,0-0.8-0.1
						c-0.2,0.2-0.3,0.4-0.3,0.6c0,0.4,0.5,0.7,1.3,0.7C301,527.1,301.6,526.8,301.6,526.4z M300.8,521.8c0-0.6-0.4-1-0.8-1
						c-0.5,0-0.8,0.3-0.8,1c0,0.6,0.4,1,0.8,1C300.5,522.8,300.8,522.4,300.8,521.8z"
							/>
							<path
								d="M304,524c-0.1-1.2,0.9-2,3.2-2.3c-0.1-0.5-0.3-0.8-1-0.8c-0.5,0-1,0.3-1.5,0.6l-0.7-1.1c0.7-0.5,1.6-0.8,2.5-0.9
						c1.5-0.1,2.4,0.8,2.5,2.6l0.1,3.4l-1.4,0.1l-0.2-0.6l0,0c-0.5,0.5-1,0.8-1.7,0.8C304.8,525.8,304,525,304,524z M307.3,523.8
						l0-1c-1.2,0.2-1.6,0.6-1.6,1c0,0.4,0.3,0.6,0.7,0.5C306.8,524.3,307,524.1,307.3,523.8z"
							/>
							<path
								d="M310.3,519.4l1.4-0.1l0.2,0.7l0,0c0.5-0.5,1-1,1.8-1c1.3-0.1,1.9,0.8,2,2.2l0.3,3.7l-1.7,0.1l-0.3-3.4
						c-0.1-0.8-0.3-1.1-0.8-1.1c-0.4,0-0.7,0.3-1,0.7l0.3,4l-1.7,0.1L310.3,519.4z"
							/>
							<path
								d="M316.8,517.1c-0.1-0.5,0.3-1,0.9-1c0.6-0.1,1,0.3,1.1,0.8c0.1,0.5-0.3,1-0.9,1C317.3,518,316.8,517.7,316.8,517.1z
						 M317.1,518.9l1.7-0.2l0.6,5.9l-1.7,0.2L317.1,518.9z"
							/>
							<path
								d="M320.3,523.8l0.6-1.2c0.6,0.3,1.1,0.5,1.6,0.4c0.5-0.1,0.7-0.3,0.7-0.6c0-0.4-0.7-0.5-1.3-0.6
						c-0.8-0.2-1.6-0.6-1.8-1.5c-0.1-1.1,0.7-1.9,2-2.1c0.9-0.1,1.6,0.2,2.2,0.5l-0.6,1.1c-0.5-0.2-0.9-0.4-1.3-0.3
						c-0.4,0.1-0.6,0.3-0.6,0.6c0,0.4,0.6,0.4,1.2,0.6c0.8,0.2,1.7,0.5,1.8,1.6c0.1,1.1-0.6,2-2.2,2.2
						C321.9,524.4,321,524.2,320.3,523.8z"
							/>
							<path
								d="M325.6,522.2c-0.2-1.2,0.7-2,3-2.6c-0.1-0.5-0.4-0.8-1.1-0.7c-0.5,0.1-0.9,0.4-1.5,0.8l-0.8-1c0.7-0.6,1.5-1,2.4-1.2
						c1.5-0.2,2.4,0.5,2.7,2.3l0.5,3.3l-1.4,0.2l-0.2-0.6l0,0c-0.4,0.5-0.9,0.9-1.6,1C326.5,523.8,325.7,523.1,325.6,522.2z
						 M328.9,521.6l-0.2-1c-1.2,0.4-1.5,0.8-1.5,1.2c0.1,0.4,0.3,0.5,0.8,0.5C328.4,522.2,328.6,521.9,328.9,521.6z"
							/>
							<path
								d="M332.1,520.6l-0.4-2.3l-0.8,0.1l-0.2-1.3l0.9-0.2l-0.1-1.6l1.4-0.3l0.3,1.5l1.4-0.3l0.2,1.3l-1.4,0.3l0.4,2.3
						c0.1,0.7,0.5,0.9,0.9,0.8c0.2,0,0.4-0.1,0.5-0.2l0.5,1.2c-0.3,0.2-0.7,0.3-1.3,0.4C333.1,522.7,332.3,521.9,332.1,520.6z"
							/>
							<path
								d="M335,514.4c-0.1-0.5,0.2-1,0.8-1.1c0.6-0.1,1.1,0.2,1.2,0.7c0.1,0.5-0.2,1-0.8,1.1C335.6,515.2,335.1,515,335,514.4z
						 M335.5,516.1l1.7-0.3l1.2,5.8l-1.7,0.3L335.5,516.1z"
							/>
							<path
								d="M338.9,518.5c-0.4-1.9,0.7-3.3,2-3.6c1.6-0.4,2.7,0.6,3.1,2.2c0.1,0.3,0.1,0.6,0.1,0.8l-3.4,0.8c0.3,0.8,1,1.1,1.8,0.9
						c0.4-0.1,0.8-0.3,1.2-0.7l0.8,0.9c-0.5,0.5-1.2,1-1.9,1.1C340.8,521.2,339.3,520.4,338.9,518.5z M342.5,517
						c-0.1-0.6-0.5-1-1.2-0.9c-0.5,0.1-0.9,0.6-0.9,1.3L342.5,517z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Toetsorganisatie</h4>
					<p>
						De kwaliteit van de hele organisatie van het toetsbouwwerk is
						belangrijk om toetskwaliteit te kunnen garanderen. Met de
						toetsorganisatie wordt verwezen naar de wijze waarop docenten,
						examencommissie, toetscommissie, management en ondersteunende
						medewerkers doelgericht met elkaar samenwerken om de gewenste
						toetskwaliteit op alle toetsentiteiten te realiseren (Van Deursen &
						Van Zijl, 2015). Daarbij gaat het om het vaststellen van rollen,
						taken en verantwoordelijkheden van actoren/betrokkenen bij toetsing,
						het rolvast met elkaar samenwerken en de logistieke organisatie van
						toetsing.
					</p>
				</div>
			</Portal>

			<Portal
				trigger={
					<g id="assessmentliteracy-bg">
						<rect x="50" y="285" width="75" height="150" fillOpacity={0} />
						<g id="assessmentliteracy">
							<path d="M77.7,311.6l0-2.1l1.5,0l0,6l-1.5,0l0-2.1l-6.3,0l0-1.8L77.7,311.6z" />
							<path
								d="M74.4,316.3c2-0.1,3.1,1.3,3.2,2.8c0,1.5-1,2.9-3,3c-2,0.1-3.1-1.3-3.2-2.8C71.3,317.8,72.4,316.4,74.4,316.3z
						 M74.5,320.2c1,0,1.7-0.4,1.6-1.1c0-0.7-0.7-1.1-1.7-1c-1,0-1.7,0.4-1.6,1.1S73.5,320.3,74.5,320.2z"
							/>
							<path
								d="M74.6,323c1.9-0.1,3.2,1.1,3.3,2.6c0.1,1.7-1,2.6-2.6,2.7c-0.3,0-0.6,0-0.8,0l-0.2-3.5c-0.8,0.2-1.2,0.8-1.1,1.6
						c0,0.4,0.2,0.8,0.5,1.2l-1,0.6c-0.5-0.6-0.8-1.3-0.8-2C71.6,324.5,72.7,323.1,74.6,323z M75.5,326.7c0.6,0,1.1-0.4,1-1.1
						c0-0.5-0.4-1-1.2-1.1L75.5,326.7z"
							/>
							<path
								d="M74.3,329.8l2.4-0.2l-0.1-0.8l1.3-0.1l0.2,0.9l1.6,0l0.1,1.4l-1.6,0.2l0.1,1.4l-1.4,0.1l-0.1-1.4l-2.3,0.2
						c-0.7,0.1-0.9,0.4-0.9,0.9c0,0.2,0.1,0.4,0.2,0.5l-1.2,0.4c-0.1-0.3-0.3-0.7-0.3-1.3C72.1,330.6,73,330,74.3,329.8z"
							/>
							<path
								d="M73.2,333.9l1.2,0.6c-0.3,0.6-0.5,1.1-0.4,1.6c0.1,0.5,0.3,0.7,0.6,0.7c0.4,0,0.5-0.7,0.6-1.3c0.2-0.8,0.6-1.6,1.5-1.8
						c1.1-0.1,1.9,0.7,2.1,2c0.1,0.9-0.2,1.6-0.5,2.2l-1.1-0.6c0.2-0.5,0.4-0.9,0.3-1.3c-0.1-0.4-0.3-0.6-0.6-0.6
						c-0.4,0-0.4,0.6-0.6,1.2c-0.2,0.8-0.5,1.7-1.6,1.8c-1.1,0.1-2-0.6-2.2-2.2C72.6,335.4,72.8,334.5,73.2,333.9z"
							/>
							<path
								d="M74.2,341.3L74.2,341.3l-0.7-0.1l-0.2-1.4l8.2-1.3l0.3,1.7l-2,0.3l-0.9,0.1c0.5,0.4,0.8,0.9,0.9,1.4
						c0.2,1.4-0.8,2.5-2.6,2.8c-2,0.3-3.3-0.7-3.6-1.9C73.6,342.4,73.8,341.8,74.2,341.3z M76.9,343.1c1-0.2,1.5-0.6,1.4-1.3
						c-0.1-0.4-0.3-0.6-0.7-0.9l-2.4,0.4c-0.2,0.4-0.3,0.7-0.2,1C75.2,342.9,75.7,343.3,76.9,343.1z"
							/>
							<path
								d="M77.3,345.8c1.9-0.4,3.3,0.7,3.6,2.1c0.3,1.6-0.7,2.7-2.3,3c-0.3,0.1-0.6,0.1-0.8,0.1l-0.7-3.5c-0.8,0.3-1.1,1-0.9,1.7
						c0.1,0.4,0.3,0.8,0.6,1.2l-0.9,0.8c-0.5-0.5-0.9-1.2-1.1-1.9C74.6,347.7,75.4,346.2,77.3,345.8z M78.7,349.5
						c0.6-0.1,1-0.5,0.9-1.2c-0.1-0.5-0.6-0.9-1.3-0.9L78.7,349.5z"
							/>
							<path
								d="M83.9,351.1l0.4,1.7l-4.6,1.1l0,0l2.7,1.3l0.4,1.8l-2.8-1.4l-2.9,3l-0.4-1.8l1.9-1.8l-1-0.5l-1.4,0.3l-0.4-1.7
						L83.9,351.1z"
							/>
							<path
								d="M82.9,357.5l0.5,1.7l-2.5,1.3c-0.5,0.3-1.1,0.5-1.6,0.7l0,0c0.6-0.1,1.2-0.1,1.8-0.1l2.8-0.1l0.4,1.5l-2.4,1.4
						c-0.5,0.3-1,0.5-1.6,0.8l0,0c0.6-0.1,1.2-0.2,1.8-0.2l2.8-0.2l0.4,1.6l-6.1,0.2l-0.6-2l2.1-1.2c0.5-0.3,1-0.5,1.6-0.8l0,0
						c-0.6,0.1-1.2,0.1-1.7,0.2l-2.4,0.1l-0.5-1.9L82.9,357.5z"
							/>
							<path
								d="M81.4,367.8c1.2-0.4,2.1,0.3,3.1,2.5c0.5-0.2,0.7-0.5,0.5-1.2c-0.2-0.5-0.5-0.9-1-1.3l0.9-0.9c0.7,0.6,1.2,1.3,1.5,2.2
						c0.5,1.4-0.1,2.5-1.8,3.1l-3.2,1l-0.4-1.4l0.5-0.3l0,0c-0.6-0.3-1-0.8-1.2-1.4C80,369,80.5,368.1,81.4,367.8z M82.5,371l1-0.3
						c-0.5-1.1-1-1.4-1.4-1.3c-0.4,0.1-0.5,0.4-0.3,0.8C81.9,370.6,82.2,370.8,82.5,371z"
							/>
							<path
								d="M83.4,373.7c1.2-0.4,2.1,0.3,3.2,2.4c0.5-0.2,0.7-0.6,0.5-1.2c-0.2-0.5-0.5-0.9-1-1.3l0.9-1c0.7,0.5,1.3,1.2,1.6,2.1
						c0.5,1.4,0,2.5-1.7,3.1l-3.2,1.1l-0.5-1.3l0.5-0.3l0,0c-0.6-0.3-1.1-0.7-1.3-1.4C81.9,375,82.4,374.1,83.4,373.7z M84.6,376.8
						l1-0.4c-0.6-1.1-1.1-1.4-1.5-1.2c-0.4,0.1-0.4,0.4-0.3,0.8C83.9,376.4,84.2,376.6,84.6,376.8z"
							/>
							<path
								d="M89.5,378.1l0.5,1.3l-0.7,0.4l0,0c0.6,0.3,1.2,0.6,1.5,1.3c0.3,0.8,0.2,1.4-0.3,1.9c0.7,0.3,1.3,0.6,1.6,1.3
						c0.5,1.2-0.1,2.1-1.5,2.6l-3.4,1.4l-0.7-1.6l3.2-1.3c0.8-0.3,0.9-0.6,0.8-1.1c-0.1-0.3-0.4-0.5-0.9-0.7l-3.7,1.5l-0.7-1.6
						l3.2-1.3c0.8-0.3,0.9-0.6,0.8-1.1c-0.1-0.3-0.4-0.5-0.9-0.7l-3.7,1.5l-0.7-1.6L89.5,378.1z"
							/>
							<path
								d="M95.5,386.2l0.7,1.6l-1.9,0.8l-1,0.4c0.5,0.2,1.2,0.6,1.5,1.3c0.5,1.2-0.1,2-1.4,2.6l-3.3,1.5l-0.7-1.6l3.1-1.4
						c0.8-0.4,0.9-0.7,0.7-1.1c-0.2-0.4-0.5-0.6-1-0.8l-3.6,1.6l-0.7-1.6L95.5,386.2z"
							/>
							<path
								d="M93.2,394.2c1.7-0.8,3.4-0.1,4,1.1c0.7,1.5,0,2.8-1.4,3.5c-0.3,0.1-0.6,0.2-0.7,0.3l-1.5-3.2c-0.7,0.5-0.8,1.2-0.5,1.9
						c0.2,0.4,0.5,0.7,0.9,1l-0.7,1c-0.6-0.4-1.2-1-1.5-1.6C91,396.8,91.4,395.1,93.2,394.2z M95.4,397.4c0.6-0.3,0.9-0.7,0.6-1.4
						c-0.2-0.5-0.8-0.8-1.5-0.5L95.4,397.4z"
							/>
							<path
								d="M98.6,398.6l0.8,1.6l-5.2,2.7l-0.8-1.6L98.6,398.6z M100.2,397.7c0.5-0.2,1,0,1.3,0.5c0.3,0.5,0.1,1.1-0.4,1.3
						c-0.5,0.2-1,0-1.3-0.5S99.7,397.9,100.2,397.7z"
							/>
							<path
								d="M97.3,402.5c1.7-0.9,3.3-0.4,3.9,0.7c0.3,0.6,0.3,1,0.1,1.6l0.8-0.5l1.8-1l0.8,1.5l-7.3,4l-0.7-1.3l0.5-0.4l0,0
						c-0.6-0.2-1.1-0.5-1.4-1C95.1,404.8,95.6,403.4,97.3,402.5z M98.2,406.4l2.1-1.2c0.1-0.4,0-0.8-0.1-1c-0.3-0.5-1-0.7-2-0.2
						c-1,0.5-1.3,1.1-1,1.7C97.4,406.2,97.7,406.4,98.2,406.4z"
							/>
						</g>
					</g>
				}
			>
				<div className="toetsmodel-component__popup">
					<h4>Toetsbekwaamheid</h4>
					<p>
						Met toetsbekwaamheid wordt in het toetsweb verwezen naar de
						deskundigheid die bij actoren binnen de opleiding aanwezig moet zijn
						om kwaliteit te realiseren op alle toetsentiteiten (Van Berkel,
						Sluijsmans & Joosten-ten Brinke, 2015). Specifiek voor de entiteit
						toetsen geldt dat de toetsbekwaamheid van docenten, examencommissies
						en andere betrokkenen van essentieel belang is: elke docent moet in
						staat zijn om toetsinformatie te interpreteren en te gebruiken om
						vast te stellen waar studenten staan en hoe deze informatie verder
						kan bijdragen aan het leren van de student (Straetmans, 2006).
					</p>
				</div>
			</Portal>
		</g>
	)
}
